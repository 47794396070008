<template>
  <div v-if="page_store.page">
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a :href="'/' + page_store.page.slug">{{
                page_store.page.title
              }}</a>
            </li>
          </ol>
        </nav>
        <hr class="mt-4" />
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-1">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h1 class="header-red">{{ page_store.page.title }}</h1>
          <EditableBlock
            v-if="page_store.blocks"
            :blocks="page_store.blocks"
            :page_id="page_store.page.id"
            block_name="block-1"
            type="blocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue"
import { pageStore } from "@/stores/page";
import EditableBlock from "@/components/EditableBlock.vue";

export default {
  name: "PageView",
  components: {
    EditableBlock,
  },
  data: function () {
    return {
      test: "test",
    };
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>
