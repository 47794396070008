<template>
  <footer class="page-footer bg-dark">
    <!-- first row-->
    <div class="pt-5 pb-0 pb-md-4">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="widget widget-links pb-4">
              <h3 class="widget-title text-white border-light">Afdelingen</h3>
              <ul>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/verhuur/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    >
                    </i>
                    <span class="font-size-sm">Verhuur AV</span>
                  </a>
                </li>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/verkoop/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Verkoop AV</span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link-inline nav-link-light"
                    href="/educatie/"
                  >
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Educatie / Opleidingen</span>
                  </a>
                </li>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/occasions/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Occasion</span>
                  </a>
                </li>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/duplicatie/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Duplicatie &amp; Productie</span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link-inline nav-link-light"
                    href="/kantoor-ruimte-huren-amsterdam/"
                  >
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Kantoorruimte huren</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="widget widget-links pb-4">
              <h3 class="widget-title text-white border-light">Account</h3>
              <ul>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/profiel/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Mijn account</span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link-inline nav-link-light"
                    href="/registratie/"
                  >
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Registreren</span>
                  </a>
                </li>
                <li>
                  <a
                    class="nav-link-inline nav-link-light"
                    href="/wachtwoord-aanvraag/"
                  >
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Wachtwoord vergeten</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="widget widget-links pb-4">
              <h3 class="widget-title text-white border-light">Contact</h3>
              <ul>
                <li>
                  <a class="nav-link-inline nav-link-light" href="/contact/">
                    <i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i>
                    <span class="font-size-sm">Contactpagina</span>
                  </a>
                </li>
              </ul>
              <p class="text-white-50 font-size-sm">
                Voor vragen of advies kunt u altijd bij ons terecht.
              </p>
              <p class="text-white-50 font-size-xs">
                Tel:
                <span itemprop="telephone" class="font-size-sm pl-1"
                  ><a href="tel:+31205246060">020 524 60 60</a></span
                >
              </p>
              <!--<p class="text-white-50 font-size-xs">
                <i data-feather="phone"></i> Backup: <span itemprop="telephone" class="font-size-sm pl-1"><a href="tel:+31202991801">020 299 18 01</a></span>
              </p>-->
              <p class="text-white-50 font-size-xs">
                <i data-feather="mail"></i>
                <span class="font-size-sm pl-1"
                  ><a
                    href="mailto:order@openstudio.nl?SUBJECT=WEB%20aanvraag%20Website&CC=mail@openstudio.nl"
                    >order@openstudio.nl</a
                  ></span
                >
              </p>
            </div>
          </div>
          <div class="col-xl-4 offset-xl-1 col-md-5">
            <div class="widget">
              <!-- Subscription form (MailChimp)-->
              <h3 class="widget-title text-white border-light">
                Nieuwsbrief / Updates
              </h3>
              <!--<form class="validate pb-4" action="https://openstudio.us1.list-manage.com/subscribe/post?u=aca1d95ba880a1ddaccdd09e9&amp;id=81a31fe7a8" method="get" name="mc-embedded-subscribe-form" id="mc-embedded-subscribe-form">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="background-color: #e8e8e8;">
                      <i data-feather="mail"></i>
                    </span>
                  </div>
                  <input class="form-control border-0 box-shadow-0 bg-secondary"
                         type="email"
                         name="EMAIL"
                         id="mce-EMAIL"
                         value=""
                         placeholder="Your email" required>
                </div>
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                  <input type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabindex="-1">
                </div>
                <button class="btn btn-primary btn-block"
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe">Subscribe*</button>
                <p class="font-size-xs text-white opacity-60 pt-2 mb-2"
                   id="mc-helper">
                  *Schrijf je in voor onze nieusbrief voor updates en productaanbiedingen.
                </p>
                <div class="subscribe-status"></div>
              </form>
              -->
              <form class="validate pb-4">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      style="background-color: #e8e8e8"
                    >
                      <i data-feather="mail"></i>
                    </span>
                  </div>
                  <input
                    class="form-control border-0 box-shadow-0 bg-secondary"
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    value=""
                    placeholder="Your email"
                    v-model="email_address"
                    required
                  />
                </div>
                <a
                  class="btn btn-info btn-block mb-4"
                  :class="{ disabled: posting }"
                  href
                  id="checkout-finish-reserve"
                  @click.prevent
                  @click="registerNewsletter"
                >
                  Inschrijven
                </a>
              </form>
              <!-- Openingstijden -->
              <div class="widget pb-4 text-white">
                <h3 class="widget-title text-white border-light">
                  Openingstijden
                </h3>
                <p class="text-white-50 font-size-sm">
                  <i data-feather="clock"></i> Maandag - Vrijdag 09:00 - 17:00
                </p>
                <p class="text-white-50 font-size-sm">
                  <i data-feather="clock"></i> Vrijdag 24 dec &amp; 31 dec 09:00
                  - 15:00
                </p>
                <!--<p class="text-white-50 font-size-sm">
                  <i data-feather="x-circle"></i> 25 &amp; 26 dec + 1 jan Gesloten
                </p>-->
                <p class="text-white-50 font-size-sm">
                  <i data-feather="x-circle"></i> Zaterdag &amp; Zondag Gesloten
                </p>
                <p class="text-white-50 font-size-sm">
                  <i data-feather="users"></i> Voor storingen en support buiten
                  kantooruren kun je contact opnemen via
                  <a
                    href="mailto:support@openstudio.nl?SUBJECT=Supportaanvraag%20Website&CC=mail@openstudio.nl"
                    >support@openstudio.nl</a
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- shop features-->
    <div
      class="pt-5 pb-0 pb-md-5 border-bottom border-light"
      id="shop-features"
      style="background-color: #1f1f1f"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 border-right border-light">
            <div class="icon-box text-center mb-5 mb-md-0">
              <div class="icon-box-icon"><i data-feather="truck"></i></div>
              <h3 class="icon-box-title font-weight-semibold text-white">
                Bezorgopties Verhuur
              </h3>
              <p class="icon-box-text">
                Naast koopitems bezorgen wij ook huurorders. Bespreek met ons de
                mogelijkheden!
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 border-right border-light">
            <div class="icon-box text-center mb-5 mb-md-0">
              <div class="icon-box-icon"><i data-feather="refresh-cw"></i></div>
              <h3 class="icon-box-title font-weight-semibold text-white">
                Vervangende apparatuur
              </h3>
              <p class="icon-box-text">
                Bij ons iets gekocht dat gerepareerd moet worden? Vraag om
                vervangende apparatuur in de tussentijd.
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 border-right border-light">
            <div class="icon-box text-center mb-5 mb-md-0">
              <div class="icon-box-icon"><i data-feather="life-buoy"></i></div>
              <h3 class="icon-box-title font-weight-semibold text-white">
                Customer support
              </h3>
              <p class="icon-box-text">
                Naast onze <a href="/contact/">openingstijden</a> proberen wij
                je met dringende zaken z.s.m te helpen via
                <a
                  href="mailto:support@openstudio.nl?SUBJECT=Supportaanvraag%20Website&CC=mail@openstudio.nl"
                  >support@openstudio.nl</a
                >
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="icon-box text-center mb-5 mb-md-0">
              <div class="icon-box-icon">
                <i data-feather="credit-card"></i>
              </div>
              <h3 class="icon-box-title font-weight-semibold text-white">
                Veilige online betalen
              </h3>
              <p class="icon-box-text">
                Deze site gebruikt ssl en mollie als payment provider
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- third row-->
    <div class="pt-5 pb-4" style="background-color: #1f1f1f">
      <div class="container">
        <div class="row pt-4">
          <div class="col-sm-2 text-center text-sm-right">
            <img
              class=""
              src="/media/uploads/homepage/openstudio-logo-white.png"
            />
          </div>
          <div class="col-sm-7 text-center text-sm-left">
            <ul class="list-inline font-size-sm">
              <li class="list-inline-item mr-3">
                <a class="nav-link-inline nav-link-light" href="/voorwaarden/"
                  >Algemene voorwaarden inzaken opleidingen</a
                >
              </li>
              <li class="list-inline-item mr-3">
                <a
                  class="nav-link-inline nav-link-light"
                  href="/algemene-voorwaarden-verkoop-verhuur-en-opdrachten/"
                >
                  Algemene voorwaarden verkoop, verhuur en opdrachten</a
                >
              </li>
              <li class="list-inline-item mr-3">
                <a class="nav-link-inline nav-link-light" href="/privacy/"
                  >Privacy Statement &amp; Cookies</a
                >
              </li>
            </ul>
          </div>
          <div class="col-sm-3 text-center text-sm-right">
            <a
              class="social-btn sb-facebook sb-light mx-1 mb-2"
              href="https://www.facebook.com/openstudio.page/"
            >
              <i class="flaticon-facebook"></i>
            </a>
            <a
              class="social-btn sb-twitter sb-light mx-1 mb-2"
              href="https://twitter.com/openstudionl?lang=nl"
            >
              <i class="flaticon-twitter"></i>
            </a>
            <a
              class="social-btn sb-instagram sb-light mx-1 mb-2"
              href="https://www.instagram.com/openstudioamsterdam/"
            >
              <i class="flaticon-instagram"></i>
            </a>
            <a
              class="social-btn sb-vimeo sb-light mx-1 mb-2"
              href="https://vimeo.com/openstudio"
            >
              <i class="flaticon-vimeo"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-center text-white pt-3">
        <ul class="list-inline mb-1 text-muted">
          <li class="list-inline-item mr-0">Open Studio &#183;</li>
          <li class="list-inline-item mr-0">Johann Siegerstraat 16 &#183;</li>
          <li class="list-inline-item mr-0">1096 BH Amsterdam</li>
        </ul>
        <ul class="list-inline text-muted">
          <li class="list-inline-item mr-0">IBAN: NL26INGB0656122781 &#183;</li>
          <li class="list-inline-item mr-0">BIC: INGBNL2A &#183;</li>
          <li class="list-inline-item mr-0">BTW: NL804870743B01 &#183;</li>
          <li class="list-inline-item mr-0">KvK: 332 758 79</li>
        </ul>
      </div>
    </div>
    <div class="py-3" style="background-color: #1a1a1a">
      <div class="container font-size-xs text-center" aria-label="Copyright">
        <span class="text-white opacity-60 mr-1"
          >Â© 2021 All rights reserved.
        </span>
        <a
          class="nav-link-inline nav-link-light"
          href="https://openstudio.nl/contact/"
          target="_blank"
          >Open Studio B.V.</a
        >
        <br />
        <span class="text-white opacity-60 mr-1"
          >Alle genoemde prijzen bij verhuur, verkoop en occasions zijn
          exclusief BTW. Prijzen bij curussen en opleidingen zijn vrijgesteld
          van BTW</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
export default {
  name: "OSFooter",
  props: {},
  data: function () {
    return {
      email_address: "",
      posting: false,
      subscribed: false,
    };
  },
  methods: {
    registerNewsletter() {
      console.log(this.email_address);
      this.posting = true;
      axios
        .post("/api/mailinglist/", {
          email_address: this.email_address,
        })
        .then(
          () => {
            this.posting = false;
            this.subscribed = true;
            this.email_address = "";
            window.$("#success-toast").toast("dispose");
            window.$("#success-toast").toast("show");
          },
          () => {
            this.posting = false;
            window.$("#unknown-error-toast").toast("dispose");
            window.$("#unknown-error-toast").toast("show");
          }
        );
    },
  },
};
</script>

<style scoped></style>
