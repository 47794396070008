<template>
  <div>
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item"><a target="_self" href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a target="_self" href="/educatie/">educatie</a>
            </li>
          </ol>
        </nav>
        <h1 class="page-title">Agenda</h1>
        <span class="d-block mt-2 text-muted"></span>
        <hr class="mt-4" />
      </div>
    </div>

    <div class="container" ng-controller="AgendaController as ctrl">
      <div class="row">
        <div class="col-lg-3">
          <!-- Shop sidebar-->
          <div class="offcanvas-sidebar">
            <div class="offcanvas-sidebar-toggle">
              <span class="toggle-knob">
                <i data-feather="chevrons-right"></i>Categorie &amp; filter
              </span>
            </div>
            <div class="offcanvas-sidebar-body">
              <div class="offcanvas-sidebar-body-inner">
                <!-- Categories-->
                <div class="widget widget-categories mb-4 py-1">
                  <h3 class="widget-title">Categorie:</h3>
                  <ul id="shopCategories" ng-cloak>
                    <li
                      v-for="cat in categories"
                      :key="cat.id"
                      style="padding: 0"
                    >
                      <router-link
                        :to="{
                          name: 'Agenda',
                          params: {
                            cat: cat.slug,
                          },
                        }"
                      >
                        {{ cat.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
                <!--<div class="widget widget-categories mb-4 py-1">
                  <h3 class="widget-title">Tags:</h3>
                  <ul id="shopCategories" ng-cloak>
                    <li ng-repeat="tag in ctrl.tag_counts" style="padding: 0">
                      <a
                        href
                        style="cursor: pointer"
                        ng-click="ctrl.fetchAgenda(null, tag[1])"
                        >{[tag[0]]}
                      </a>
                    </li>
                  </ul>
                </div>-->
                <!-- Promo banner-->
                <a class="d-block text-decoration-0" href="/inloopdagen/">
                  <div class="bg-secondary">
                    <div class="px-3 pt-4 text-center">
                      <h4 class="font-size-sm font-weight-normal pt-1 mb-2">
                        Aankomende
                      </h4>
                      <h4 class="h5 pb-2">Infodag</h4>
                      <div class="btn btn-primary btn-sm">Meer info</div>
                    </div>
                    <OpenDoorDayWidget size="small" /></div
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9" ng-cloak>
          <div class="row pb-3">
            <div
              itemscope
              itemtype="https://schema.org/Event"
              v-for="run in runs"
              :key="run.id"
              class="mb-3 col-md-6 px-3 animate-repeat"
            >
              <div
                class="card"
                style="width: 100%; height: 100%"
                itemprop="startDate"
                datetime="run.start_date.format('YYYY-MM-DD')"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="card-body">
                      <h5 class="card-title" style="height: 2.4em">
                        <a
                          style="color: #222222"
                          :href="`/opleidingen/${run.course.slug}`"
                        >
                          {{ run.course.name }}
                        </a>
                      </h5>
                      <p itemprop="description" class="card-text font-size-sm">
                        <span class="font-italic"
                          >{{ moment(run.start_date).format("DD MMMM YYYY") }}.
                        </span>
                        <span
                          v-if="run.course.seo_description"
                          class="text-muted course-description"
                          v-html="run.course.seo_description"
                        ></span>
                        <span
                          v-else
                          class="text-muted course-description"
                          v-html="run.course.description"
                        ></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue";

export default {
  name: "CalendarView",
  props: {},
  components: {
    OpenDoorDayWidget,
  },
  data: function () {
    return {
      runs: [],
      categories: [],
    };
  },
  methods: {
    fetchData() {
      let params = {
        params: {
          has_course: true,
          course__categories__slug: this.$route.params.cat,
          starts_later: moment().format("YYYY-MM-DD"),
          page_size: 20,
        },
      };
      axios.get("/api/runs/", params).then((response) => {
        this.runs = response.data.results;
      });
      params.params.no_page = true;
      axios
        .get("/api/categories/", params)
        .then(
          (response) => (this.categories = _.sortBy(response.data, ["name"]))
        );
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      { immediate: true }
    );
  },
};
</script>

<style>
.course-description > p:first-child {
  display: inline;
}
</style>
