<template>
  <div>
    <!-- Page Title-->
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item"><a target="_self" href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a target="_self" href="/educatie/">educatie</a>
            </li>
          </ol>
        </nav>
        <h1 v-if="page_store.page" class="page-title">
          {{ page_store.page.title }}
        </h1>
        <span class="d-block mt-2 text-muted"></span>
        <hr class="mt-4" />
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-1">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <!-- Gallery-->
          <div>
              <img
                src="/media/uploads/images/infodag-tussenjaar.jpg"
                class="img-responsive pb-4"
                alt="open studio open dag video en digitale media"
              />
          </div>
          <!-- Post content-->
          <EditableBlock
            v-if="page_store.blocks"
            :blocks="page_store.blocks"
            :page_id="page_store.page.id"
            block_name="block-1"
            type="blocks"
          />
          <OpenDoorDayRegistrationWidget/>
          <EditableBlock
            v-if="page_store.blocks"
            :blocks="page_store.blocks"
            :page_id="page_store.page.id"
            block_name="block-2"
            type="blocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue"
import { pageStore } from "@/stores/page";
import EditableBlock from "@/components/EditableBlock.vue";
import OpenDoorDayRegistrationWidget from "@/components/OpenDoorDayRegistrationWidget.vue";

export default {
  name: "OpenDoorDayView",
  components: {
    EditableBlock,
    OpenDoorDayRegistrationWidget,
  },
  data: function () {
    return {
      test: "test",
    };
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>
