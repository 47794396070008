<template>
  <div id="activate-account-view" class="container pb-5 mb-sm-4">
    <div class="row pt-5">
      <div class="col-md-6 pt-sm-3">
        <div class="card">
          <div v-if="failed" class="card-body">
            <p>Verificatie mislukt. Wellicht is de activatielink verlopen.</p>
          </div>
          <div v-else-if="askpass" class="card-body">
            <h4 class="h4 mb-1">Stel een nieuw wachtwoord in</h4>
            <PasswordWidget />
          </div>
          <div v-else-if="user" class="card-body">
            <p>Je bent ingelogd.</p>
          </div>
          <div v-else-if="token" class="card-body">
            <h4 class="h4 mb-1">Stel een nieuw wachtwoord in</h4>
            <PasswordWidget />
          </div>
          <div v-else-if="$route.query.cp" class="card-body">
            <p>
              Je hebt een email ontvangen met een link om je wachtwoord te
              wijzigen.
            </p>
          </div>
          <div v-else class="card-body">
            <p>
              Je account moet worden geactiveerd. Je hebt een email ontvangen
              met een activatielink.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordWidget from "@/components/PasswordWidget.vue";
import { userStore } from "@/stores/user";

export default {
  name: "ActivateAccountView",
  components: {
    PasswordWidget,
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    askpass() {
      return this.$route.query.askpass;
    },
    failed() {
      return this.$route.query.failed;
    },
    user() {
      return this.store.user;
    },
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
};
</script>
