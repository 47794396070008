<template>
  <input
    :id="id"
    :class="cls"
    @input="handleInput"
    class="form-control"
    type="text"
    placeholder=""
    aria-label=""
    autocomplete="chrome-off,false"
  />
</template>

<script>
import Litepicker from "litepicker";
import "litepicker/dist/css/litepicker.css";
import moment from "moment";

export default {
  name: "SimpleLitePicker",
  props: [
    "id",
    "cls",
    "value",
  ],
  components: {},
  methods: {
    handleInput () {
    }
  },
  computed: {},
  data() {
    return {
      content: this.value
    }
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {

      const picker = new Litepicker({
        element: document.getElementById(this.id),
        lang: "nl-NL",
        numberOfMonths: 1,
        numberOfColumns: 1,
        format: "D MMMM YYYY",
        singleMode: true,
        autoRefresh: true,
        dropdowns: {
          years: true,
          months: true,
          minYear: 1940
        },
      });
      picker.on("selected", (date1) => {
        this.sdate.selected_date = moment(date1.dateInstance);
        console.log(this.sdate.selected_date);
        this.$emit('input', this.sdate.selected_date.format(moment.HTML5_FMT.DATE))
      });
    });
  },
  setup() {
    const sdate = {};
    return {
      sdate
    };
  },
};
</script>

<style>
</style>
