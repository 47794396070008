// import moment from "moment";
// import Vue from "vue";
import _ from "lodash";
import axios from "axios";
import Vue from "vue";
import { defineStore } from "pinia";

const TTL = 60 * 60 * 24;
const KEY = "checkout.v1.2";

const saveState = function (checkout) {
  window.setWithExpiry(
    KEY,
    {
      billing_address: checkout.billing_address || {},
      delivery_address: checkout.delivery_address || {},
      sam_entity: checkout.sam_entity || null,
    },
    TTL
  );
};
const loadState = function () {
  return {
    checkout: window.getWithExpiry(KEY, {
      billing_address: {},
      delivery_address: {},
      sam_entity: null,
      remarks: "",
      newsletter: false,
    }),
  };
};

export const checkoutStore = defineStore("checkout", {
  state: () => {
    let s = loadState();
    saveState(s.checkout);
    return s;
  },
  getters: {},
  actions: {
    saveState() {
      saveState(this.checkout);
    },
    async placeOrder(cart, payNow) {
      let payload = { ...this.checkout };
      var headers = {
        "X-CSRFToken": Vue.$cookies.get("csrftoken"),
      };
      var params = {
        pay_now: payNow,
      };
      if (_.values(payload.delivery_address).length == 0) {
        delete payload.delivery_address;
      }
      return await axios
        .post("/api/shop/order/", payload, { headers, params })
        .then((response) => {
          cart.clear();
          this.clear();
          return response;
        });
    },
    async validateAndSave() {
      let payload = { ...this.checkout };
      var headers = {
        "X-CSRFToken": Vue.$cookies.get("csrftoken"),
      };
      // payload.cart = cart.serialize();
      if (_.values(payload.delivery_address).length == 0) {
        delete payload.delivery_address;
      }
      console.log(payload);
      return await axios
        .post("/api/shop/order/", payload, {
          params: { validate: true },
          headers,
        })
        .then(
          () => {
            saveState(this.checkout);
          },
          (error) => {
            throw error.response.data;
          }
        );
    },
    clear() {
      window.localStorage.removeItem(KEY);
      this.$reset();
    },
  },
});
