<template>
  <div class="row rentalarticlelist">
    <div class="col-sm-3 col-5">
      <div class="product-thumb">
        <a
          :href="'/verhuur/artikel/' + article.slug"
          style="display: flex; align-items: center"
          class="quick-view- product-thumb-link"
        >
          <img
            class="mx-auto mt-4 product-image"
            :src="'/media/' + article.image_large"
            alt="Product image"
          />
        </a>
      </div>
    </div>
    <div class="col-sm-9 col-7">
      <div class="row pt-4">
        <div class="col-sm-8 h-100">
          <div class="product-title">
            <span class="small">
              {{ article.brand }}
            </span>
            <h6 class="card-title mt-1">
              <a :href="'/verhuur/artikel/' + article.slug" class="text-dark">
                {{ article.name }}
              </a>
            </h6>
            <span class="small text-muted">
              {{ article.description }}
            </span>
          </div>
        </div>
        <div class="col-sm-4 align-items-center">
          <div
            v-bind:class="{ 'd-none': article.rent_total !== null }"
            class="mt-2 period-price"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            class="period-price mt-2"
            v-bind:class="{ 'd-none': article.rent_total === null }"
          >
            <p class="h4 text-success mb-0"
              >&euro; {{ article.rent_total | toFloat | toAmount }}</p
            ><span v-if="article.discount" class="small text-info pb-2">
              Klantkorting: &euro; {{article.discount  | toFloat | toAmount }} 
              <!--<span class="d-none d-md-inline">Huurprijs</span>
              {{ moment(this.cart.cart.start_date).format("D MMMM") }} -
              {{ moment(this.cart.cart.end_date).format("D MMMM") }}--></span
            >
          </div>
          <div class="day-price" href="#">
            <span class="small text-muted pb-1">
              Dagprijs &euro; {{ article.price | toFloat | toAmount }}
            </span>
          </div>
          <div class="flex-fill mt-3 mb-3">
            <button
              class="btn btn-outline-primary py-2 px-2 add-to-cart-button"
              type="button"
              data-toggle="offcanvas"
              data-target="#offcanvas-cart"
              v-on:click="addToBasket(article)"
            >
              <i class="mr-1 feather-20" data-feather="plus"></i>
              <i class="mr-1 feather-20" data-feather="shopping-cart"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentalArticle",
  props: ["article", "cart"],
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
    toAmount: function (value) {
      return parseFloat(value.toFixed(2)).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
      });
    },
  },
  methods: {
    addToBasket(article) {
      this.cart.addArticle(article);
      window.$("#add-to-cart-toast").toast("dispose");
      window.$("#add-to-cart-toast").toast("show");
    },
  },
};
</script>

<style scoped>
.product-thumb {
  height: 100%;
}
.product-image {
  max-width: 100%;
  max-height: 80%;
  display: block;
}
.rentalarticlelist {
  border-bottom: 1px solid #e7e7e7;
}
@media (max-width: 576px) {
  .prod-thumb {
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .rentalarticlelist {
    height: 200px;
  }
  .prod-thumb {
    max-width: 100%;
  }
}
.prod-thumb {
  max-height: 100%;
  margin: 0 auto;
  display: block;
}
.feather-20 {
  width: 20px !important ;
  height: 20px !important ;
}
</style>
