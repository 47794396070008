<template>
  <header
    class="navbar navbar-expand-lg navbar-light fixed-top bg-light px-0 px-sm-1"
    data-ng-controller="UserController as userCtrl"
  >
    <div class="container-fluid navbar-inner" ng-cloak>
      <!-- navbar brand-->
      <div
        class="d-block d-sm-none"
        style="min-width: 100px; margin-left: 10px"
      >
        <a class="navbar-brand" href="/">
          <img
            width="100"
            src="/static/main/openstudio-logo.png"
            alt="Open Studio"
          />
        </a>
      </div>
      <div class="d-none d-sm-block ml-3 ml-lg-0" style="min-width: 155px">
        <a class="navbar-brand" href="/" style="display: block">
          <img
            width="155"
            src="/static/main/openstudio-logo.png"
            alt="Open Studio"
          />
        </a>
      </div>
      <!-- navbar collapse area-->
      <div class="collapse navbar-collapse" id="menu">
        <!-- language/currency switcher-->
        <!-- -.navbar-lang-switcher.dropdown.pr-2
        div(data-toggle='dropdown').dropdown-toggle
          +image('img/flags/en.png', 'English')(width='20')
          span USD
        ul.dropdown-menu
          li.dropdown-item
            select.custom-select.custom-select-sm
              option(value='usd') $ USD
              option(value='usd') â‚¬ EUR
              option(value='usd') Â£ UKP
              option(value='usd') Â¥ JPY
          li
            a(href='index.html').dropdown-item
              +image('img/flags/fr.png', 'FranÃ§ais')(class='mr-2' width='20')
              | FranÃ§ais
          li.dropdown-divider
          li
            a(href='#').dropdown-item
              +image('img/flags/de.png', 'Deutsch')(class='mr-2' width='20')
              | Deutsch
          li.dropdown-divider
          li
            a(href='#').dropdown-item
              +image('img/flags/it.png', 'Italiano')(class='mr-2' width='20')
              | Italiano

        -->
        <!-- Site menu-->
        <ul class="navbar-nav">
          <li class="nav-item dropdown mega-dropdown dropdown-more">
            <!--<router-link to="/verhuur"
                       target="_self"
                       class="nav-link dropdown-toggle">
            Verhuur<i data-feather="more-horizontal"></i>
          </router-link>-->
            <a
              class="nav-link dropdown-toggle"
              href="/verhuur/"
              data-hover="dropdown"
              >Verhuur<i data-feather="more-horizontal"> </i>
            </a>
            <div class="dropdown-menu">
              <div class="dropdown-inner">
                <div style="display: table-row">
                  <div class="dropdown-column">
                    <div
                      class="bg-position-center bg-no-repeat bg-size-cover text-center px-3 py-4 mb-3"
                      style="
                        background-image: url(/media/_versions/images/menu-cine-video_medium.jpg);
                      "
                    >
                      <h3 class="h5 text-white text-shadow my-3">
                        CINE / VIDEO
                      </h3>
                    </div>
                    <div class="widget widget-links">
                      <ul>
                        <li>
                          <a target="_self" href="/verhuur/camera/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Camera's</span></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/lenzen/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Lenzen</span></a
                          >
                        </li>
                        <li>
                          <a
                            target="_self"
                            href="/verhuur/monitor/wireless-video"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Wireless Video</span></a
                          >
                        </li>
                        <li>
                          <a
                            target="_self"
                            href="/verhuur/videoproductie-live/videomixer"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Videomixers</span></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/camera-accessoire/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm"
                              >Camera Accesoire</span
                            ></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="dropdown-column">
                    <div
                      class="bg-position-center bg-no-repeat bg-size-cover text-center px-3 py-4 mb-3"
                      style="
                        background-image: url(/media/_versions/images/menu-licht-audio_medium.jpg);
                      "
                    >
                      <h3 class="h5 text-white text-shadow my-3">
                        LICHT &amp; GELUID
                      </h3>
                    </div>
                    <div class="widget widget-links">
                      <ul>
                        <li>
                          <a target="_self" href="/verhuur/licht/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Licht</span></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/grip-licht/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm"
                              >Licht / Grip Accesoires</span
                            ></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/audio/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Audio</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="dropdown-column">
                    <div
                      class="bg-position-center bg-no-repeat bg-size-cover text-center px-3 py-4 mb-3"
                      style="
                        background-image: url(/media/_versions/images/productie_medium.jpg);
                      "
                    >
                      <h3 class="h5 text-white text-shadow my-3">OVERIGE</h3>
                    </div>
                    <div class="widget widget-links">
                      <ul>
                        <li>
                          <a target="_self" href="/verhuur/montage-pc-mac/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm"
                              >Edit PC + Laptop WIN / MAC</span
                            ></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/beamer/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Projectie</span></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/personeel/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm"
                              >Crew / Personeel</span
                            ></a
                          >
                        </li>
                        <li>
                          <a target="_self" href="/verhuur/studio-rooms/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Studio Huren</span></a
                          >
                        </li>
                        <li>
                          <a
                            target="_self"
                            href="/kantoor-ruimte-huren-amsterdam/"
                            ><i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i
                            ><span class="font-size-sm">Kantoorruimte</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div style="display: table-row">
                  <div class="dropdown-column pt-0">
                    <div class="widget widget-links">
                      <ul>
                        <li>
                          <a target="_self" href="/verhuur/">
                            <i
                              class="widget-categories-indicator"
                              data-feather="chevron-right"
                            ></i>
                            <span class="font-size-sm"
                              >Alle verhuur artikelen</span
                            >
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
              >Educatie</a
            >
            <ul class="dropdown-menu">
              <li :key="section.id" v-for="section in sections">
                <a
                  v-if="!section.disabled"
                  class="dropdown-item"
                  :href="'/educatie/' + section.slug + '/'"
                  target="_self"
                  >{{ section.menu_name || section.name }}</a
                >
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <a class="dropdown-item" href="/agenda/" target="_self"
                  >Agenda</a
                >
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <a class="dropdown-item" href="/infodag/" target="_self"
                  >Infodag</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/verkoop/">Verkoop</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/occasions/">Occasions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/duplicatie/">Duplicatie</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contact/">Contact</a>
          </li>
        </ul>
      </div>
      <!-- navbar buttons-->
      <div class="navbar-btns">
        <div class="navbar-btns-inner">
          <div
            class="navbar-toggler navbar-btn collapsed"
            data-toggle="collapse"
            data-target="#menu"
            id="navbar-menu"
          >
            <i class="mx-auto mb-1" data-feather="menu"></i>Menu
          </div>
          <a
            class="navbar-btn"
            href="#offcanvas-search"
            data-toggle="offcanvas"
          >
            <i class="mx-auto mb-1" data-feather="search"></i>Zoeken
          </a>
          <a
            class="navbar-btn ng-show ng-cloak"
            href="#offcanvas-account"
            id="account-toggle"
            data-toggle="offcanvas"
            v-bind:class="{ 'd-none': store.user }"
          >
            <div>
              <i class="mx-auto mb-1" data-feather="log-in"></i>Sign In/Up
            </div>
          </a>
          <a
            class="navbar-btn"
            href="/account"
            id="nav-item-profile"
            v-bind:class="{ 'd-none': !store.user }"
          >
            <div><i class="mx-auto mb-1" data-feather="user"></i>Account</div>
          </a>
          <a
            id="cart-toggle"
            class="navbar-btn"
            data-toggle="offcanvas"
            href="#offcanvas-cart"
          >
            <span class="d-block position-relative">
              <span v-if="cart.cart.count>0" id="cart-count" class="ng-cloak navbar-btn-badge bg-primary text-light">{{
                cart.cart.count
              }}</span>
              <span v-else class="ng-cloak navbar-btn-badge bg-primary text-light">0</span>
              <i class="mx-auto mb-1" data-feather="shopping-cart"></i>
              <span>&euro;{{ cart.total_articles | toFloat | toAmount }}</span>
              <!--<span ng-show="cartCtrl.days < 11" class="ng-cloak"></span>
              <span ng-show="cartCtrl.days > 10" class="ng-cloak"></span>-->
            </span>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { userStore } from "@/stores/user";
import { cartStore } from "@/stores/cart";
import axios from "axios";

export default {
  name: "NavBar",
  props: {},
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
    toAmount: function (value) {
      return parseFloat(value.toFixed(2)).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
      });
    },
  },
  data: function () {
    return {
      sections: [],
    };
  },
  computed: {
    user() {
      return this.store.user;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/sections/?no_page=true").then((response) => {
        this.sections = response.data;
      });
    },
  },
  setup() {
    const store = userStore();
    const cart = cartStore();
    return {
      store,
      cart,
    };
  },
};
</script>

<style scoped>
.navbar-brand {
  margin-right: 0;
}
</style>
