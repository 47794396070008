<template>
  <div class="mt-4 widget widget-links">
    <h3 class="widget-title">Infodag</h3>
    <!--infodag-->
    <div class="card mb-4">
      <div class="card-body">
        <div class="icon-box d-flex align-items-center text-center">
          <div class="icon-box-icon my-2 mr-3">
            <i data-feather="calendar"></i>
          </div>
          <!--end icon-->
          <div class="icon-box-body">
            <h3 class="h4 icon-box-title text-left">
              Aankomende infodagen
            </h3>
          </div>
          <!--end iconbox body-->
        </div>
        <!--end icon box-->
        <!--<h5 class="card-title">Card title</h5>-->
      </div>
      <OpenDoorDayWidget :size="size" />
      <div class="card-body">
        <a href="/infodag/" class="btn btn-success btn-block"
          >Meld je aan voor de infodag
        </a>
      </div>
    </div>
    <!--end date card sign up-->
  </div>
</template>

<script>
import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue";

export default {
  name: "InfodagWidget",
  props: ["size"],
  components: {
    OpenDoorDayWidget,
  },
  data: function () {
    return {
      days: [],
    };
  },
};
</script>

<style scoped></style>
