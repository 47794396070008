<template>
  <div>
    <!-- Page Title-->
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item"><a target="_self" href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a target="_self" href="/educatie/">educatie</a>
            </li>
          </ol>
        </nav>
        <h1 v-if="run.course" class="page-title">
          Inschrijven {{ run.course.name }}
        </h1>
        <span class="d-block mt-2 text-muted"></span>
        <hr class="mt-4" />
      </div>
    </div>
    <div class="container pb-5 mb-4">
      <div class="row">
        <div class="col-md-8" v-bind:class="{ 'd-none': is_send }">
          <form id="regform" novalidate @submit.prevent>
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="f1">Voornaam *</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.first_name }"
                  type="text"
                  v-model="form.first_name"
                />
                <div class="invalid-feedback" v-if="errors.first_name">
                  {{ errors.first_name[0] }}
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="f2">Achternaam *</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.last_name }"
                  type="text"
                  id="f2"
                  v-model="form.last_name"
                />
                <div class="invalid-feedback" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="f3">Email *</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.email }"
                  type="email"
                  id="f3"
                  v-model="form.email"
                />
                <div class="invalid-feedback" v-if="errors.email">
                  {{ errors.email[0] }}
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="f4">Telefoon *</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.phone }"
                  type="text"
                  id="f4"
                  v-model="form.phone"
                />
                <div class="invalid-feedback" v-if="errors.phone">
                  {{ errors.phone[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 form-group">
                <label for="f5">Straatnaam</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.street }"
                  type="text"
                  id="f5"
                  v-model="form.street"
                />
                <div class="invalid-feedback" v-if="errors.street">
                  {{ errors.street[0] }}
                </div>
              </div>
              <div class="col-md-2 form-group">
                <label for="f6">Huisnummer</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.house_number }"
                  v-model="form.house_number"
                  type="text"
                  id="f6"
                />
                <div class="invalid-feedback" v-if="errors.house_number">
                  {{ errors.house_number[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="f7">Postcode</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.postal_code }"
                  v-model="form.postal_code"
                  type="test"
                  id="f7"
                />
                <div class="invalid-feedback" v-if="errors.postal_code">
                  {{ errors.postal_code[0] }}
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="f8">Plaatsnaam</label>
                <input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.city }"
                  v-model="form.city"
                  type="text"
                  id="f8"
                />
                <div class="invalid-feedback" v-if="errors.city">
                  {{ errors.city[0] }}
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="fdob">Geboortedatum</label>
                <SimpleLitePicker
                  id="fdob"
                  v-bind:cls="{ 'is-invalid': errors.date_of_birth }"
                  v-model="form.date_of_birth"
                />
                <!--<input
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.date_of_birth }"
                  v-model="form.date_of_birth"
                  type="text"
                  id="f8"
                />-->
                <div class="invalid-feedback" v-if="errors.date_of_birth">
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <label for="f9">Welke ervaring heb je al met film?</label>
                <textarea
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.experience }"
                  v-model="form.experience"
                  id="f9"
                  rows="5"
                ></textarea>
                <div class="invalid-feedback" v-if="errors.experience">
                  {{ errors.experience[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <label for="f10">Vragen? Of een kortingscode? Geef het hier door</label>
                <textarea
                  class="form-control"
                  v-bind:class="{ 'is-invalid': errors.remarks }"
                  v-model="form.remarks"
                  id="f10"
                  rows="5"
                ></textarea>
                <div class="invalid-feedback" v-if="errors.remarks">
                  {{ errors.remarks[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group mt-1">
                Betaalgegevens
                <!--<div class="custom-control custom-checkbox mt-1">
                  <input
                    class="custom-control-input"
                    v-model="form.apply_for_stap"
                    type="checkbox"
                    id="ex-check-STAP"
                    :disabled="!run.course.stap_cursus || !run.stap_possible"
                  />
                  <label class="custom-control-label" for="ex-check-STAP"
                    >Ik wil gebruik maken van STAP-subsidie
                    <span v-if="run.course.stap_cursus && !run.stap_possible"
                      >(STAP niet mogelijk op dit moment)</span
                    >
                    <span v-if="!run.course.stap_cursus"
                      >(STAP bij deze cursus niet mogelijk)</span
                    ></label
                  >
                </div>-->
              </div>
              <div class="col-md-12 form-group">
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    id="fpayself"
                    v-model="form.payer"
                    v-bind:class="{ 'is-invalid': errors.payer }"
                    value="S"
                    name="radio1"
                    :disabled="form.apply_for_stap"
                  />
                  <label class="custom-control-label" for="fpayself"
                    >Ik betaal zelf</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    v-bind:class="{ 'is-invalid': errors.payer }"
                    v-model="form.payer"
                    value="O"
                    id="fpayother"
                    :disabled="form.apply_for_stap"
                    name="radio1"
                  />
                  <label class="custom-control-label" for="fpayother"
                    >Ik betaal niet zelf</label
                  >
                  <div class="invalid-feedback" v-if="errors.payer">
                    {{ errors.payer[0] }}
                  </div>
                </div>
                <div
                  class="betaalderde px-4 py-2"
                  v-bind:class="{ 'd-none': !form.payer || form.payer != 'O' }"
                >
                  <div class="form-group">
                    <label for="f11">Gegevens betalende partij</label>
                    <textarea
                      class="form-control"
                      v-model="form.payer_details"
                      v-bind:class="{ 'is-invalid': errors.payer_details }"
                      :disabled="form.apply_for_stap"
                      id="f9"
                      rows="5"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.payer_details">
                      {{ errors.payer_details[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 form-group">
                Betaalwijze
                <div class="custom-control custom-radio mt-1">
                  <input
                    class="custom-control-input"
                    type="radio"
                    value="T"
                    v-model="form.payment"
                    v-bind:class="{ 'is-invalid': errors.payment }"
                    id="fpaytransfer"
                    :disabled="form.apply_for_stap"
                    name="radio2"
                  />
                  <label class="custom-control-label" for="fpaytransfer"
                    >Bedrag overmaken</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    value="I"
                    v-model="form.payment"
                    v-bind:class="{ 'is-invalid': errors.payment }"
                    id="fpayauto"
                    :disabled="form.apply_for_stap"
                    name="radio2"
                  />
                  <label class="custom-control-label" for="fpayauto"
                    >Machtiging afgeven voor maandelijkse termijnen</label
                  >
                  <div class="invalid-feedback" v-if="errors.payment">
                    {{ errors.payment[0] }}
                  </div>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    value="V"
                    v-model="form.payment"
                    v-bind:class="{ 'is-invalid': errors.payment }"
                    id="fpaytentative"
                    name="radio2"
                  />
                  <label class="custom-control-label" for="fpaytentative"
                    >Betaalwijze nog niet bekend (Voorlopige inschrijving)</label
                  >
                  <div class="invalid-feedback" v-if="errors.payment">
                    {{ errors.payment[0] }}
                  </div>
                </div>
                <div
                  v-bind:class="{
                    'd-none': !form.payment || form.payment != 'I',
                  }"
                  class="machtiging px-4 py-2"
                >
                  <p>
                    Let op; een machtiging voor betalen in termijnen kan nooit
                    langer zijn dan de duur van de opleiding. Dus als een
                    opleiding 4 maanden duurt kun je niet kiezen voor 6
                    termijnen. Indien het een cursus betreft van een beperkt
                    aantal dagen kun je alleen kiezen voor een machtiging in 1
                    termijn. Met kiezen voor een machtiging ga je akkoord met de
                    voorwaarden.
                  </p>
                  <div class="form-group">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        value="ON"
                        v-bind:class="{ 'is-invalid': errors.payment_terms }"
                        v-model="form.payment_terms"
                        :disabled="form.apply_for_stap"
                        type="radio"
                        id="eenmalig"
                        name="radio1-a"
                      />
                      <label class="custom-control-label" for="eenmalig"
                        >Eenmalig</label
                      >
                    </div>
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        value="TH"
                        v-model="form.payment_terms"
                        v-bind:class="{ 'is-invalid': errors.payment_terms }"
                        :disabled="form.apply_for_stap"
                        type="radio"
                        id="3term"
                        name="radio1-b"
                      />
                      <label class="custom-control-label" for="3term"
                        >3 termijnen over 3 maanden + €30 adm. kosten</label
                      >
                    </div>
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        value="SI"
                        v-model="form.payment_terms"
                        v-bind:class="{ 'is-invalid': errors.payment_terms }"
                        type="radio"
                        :disabled="form.apply_for_stap"
                        id="6term"
                        name="radio1-b"
                      />
                      <label class="custom-control-label" for="6term"
                        >6 termijnen over 6 maanden + €60 adm. kosten</label
                      >
                      <div class="invalid-feedback" v-if="errors.payment_terms">
                        {{ errors.payment_terms[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="f12">IBAN rekeningnummer</label>
                    <input
                      class="form-control"
                      v-model="form.iban"
                      v-bind:class="{ 'is-invalid': errors.iban }"
                      type="text"
                      :disabled="form.apply_for_stap"
                      id="f12"
                    />
                    <div class="invalid-feedback" v-if="errors.iban">
                      {{ errors.iban[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 form-group">
                <!--<div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    v-model="form.cjp"
                    type="checkbox"
                    :disabled="form.apply_for_stap"
                    id="ex-check-1"
                  />
                  <label class="custom-control-label" for="ex-check-1"
                    >CJP-lid? (2.5% korting)</label
                  >
                </div>-->
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    v-model="form.open_door_day"
                    type="checkbox"
                    id="ex-check-2"
                  />
                  <label class="custom-control-label" for="ex-check-2"
                    >Ik ben al op een inloopdag geweest</label
                  >
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input form-control"
                    v-model="form.accept"
                    v-bind:class="{ 'is-invalid': errors.accept }"
                    type="checkbox"
                    id="ex-check-3"
                  />
                  <label class="custom-control-label" for="ex-check-3"
                    >Ik ga akkoord met de
                    <a href="/voorwaarden/" target="_blank">voorwaarden</a> ook
                    voor machtiging indien optie gekozen.</label
                  >
                  <div class="invalid-feedback" v-if="errors.accept">
                    {{ errors.accept[0] }}
                  </div>
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary"
              type="submit"
              :disabled="is_working"
              v-on:click="sendForm"
              v-bind:class="{ disabled: is_working }"
            >
              Nu inschrijven!
            </button>
          </form>
        </div>
        <div class="col-md-8" v-bind:class="{ 'd-none': !is_send }">
          <h3>Bedankt! Je inschrijving is ontvangen.</h3>
        </div>
        <div class="col-md-4" id="infoPanels">
          <div class="accordion pt4">
            <div class="card">
              <div class="card-header">
                <h3 class="accordion-heading">
                  <a
                    href=""
                    role="button"
                    data-toggle="collapse"
                    data-target="#cursusData"
                    aria-expanded="true"
                    aria-controls="cursusData"
                  >
                    <span
                      class="d-inline-block pr-2 border-right mr-2 align-middle mt-n1"
                    >
                      <i
                        data-feather="info"
                        style="width: 1.1rem; height: 1.1rem"
                      ></i> </span
                    >Cursus details
                    <span class="accordion-indicator">
                      <i data-feather="chevron-up"></i>
                    </span>
                  </a>
                </h3>
              </div>
              <div
                class="collapse show"
                id="cursusData"
                data-parent="#infoPanels"
              >
                <div class="card-body p-0" ng-cloak>
                  <div class="list-group">
                    <div
                      class="list-group-item flex-column align-items-start border-bottom-0 border-left-0 border-right-0"
                    >
                      <div
                        class="d-flex flex-wrap w-100 justify-content-between"
                      >
                        <h6>Start: {{ run.start }}</h6>
                      </div>
                      <div
                        class="d-flex flex-wrap w-100 justify-content-between"
                      >
                        <small class="text-muted mb-2"
                          >Cursusnummer: {{ run.id }}</small
                        >
                      </div>
                      <p class="font-size-sm font-weight-normal text-body">
                        {{ run.remarks }}
                      </p>
                      <div
                        class="d-flex flex-wrap w-100 justify-content-between align-items-center"
                      >
                        <h6 v-if="run.course" class="">
                          &euro;{{ run.course.price | toFloat }},-
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="accordion-heading">
                  <a
                    href=""
                    class="collapsed"
                    role="button"
                    data-toggle="collapse"
                    data-target="#inloopDagen"
                    aria-expanded="true"
                    aria-controls="inloopDagen"
                  >
                    <span
                      class="d-inline-block pr-2 border-right mr-2 align-middle mt-n1"
                    >
                      <i
                        data-feather="coffee"
                        style="width: 1.1rem; height: 1.1rem"
                      ></i> </span
                    >Infodag
                    <span class="accordion-indicator">
                      <i data-feather="chevron-up"></i>
                    </span>
                  </a>
                </h3>
              </div>
              <div class="collapse" id="inloopDagen" data-parent="#infoPanels">
                <div class="card-body">
                  <OpenDoorDayWidget size="small" />
                  <p>
                    <a href="/infodag/">Meer informatie over deze dagen.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue";
import SimpleLitePicker from "@/components/SimpleLitePicker.vue";
//import { userStore } from "@/stores/user";

export default {
  name: "CourseRegisterView",
  components: {
    OpenDoorDayWidget,
    SimpleLitePicker,
  },
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
  },
  data: function () {
    return {
      is_send: false,
      is_working: false,
      form: {},
      errors: {},
      run: { course: {} },
    };
  },
  methods: {
    sendForm() {
      this.form.run = this.run.id;
      this.form.course = this.run.course.id;
      this.is_working = true;
      if (!this.is_send) {
        axios.post("/api/courseregistration/", this.form).then(
          () => {
            window.location = "/inschrijving-bedankt";
          },
          (error) => {
            this.is_working = false;
            if (error.response) {
              this.errors = error.response.data;
            }
          }
        );
      }
    },
    fetchData() {
      var vm = this;
      axios
        .get("/api/runs/" + this.$route.params.run + "/")
        .then((response) => {
          this.run = response.data;
          this.run.shortDates = this.run.dates
            .map(function (d) {
              return vm.moment(d.date).format("DD/MM");
            })
            .join(", ");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
