<template>
  <div id="course">
    <div class="page-title-wrapper" aria-label="Page title">
      <!-- Page Title-->
      <div class="container edutainer">
        <div class="row no-gutters bg-secondary">
          <div class="col-md-6 px-3 px-md-5 py-5">
            <div class="mx-auto py-lg-5" style="max-width: 35rem">
              <h2 class="h3 pb-3">
                {{ course.name }}
              </h2>
              <p class="pb-3 opacity-75">
                {{ course.seo_description }}
              </p>
              <a class="btn btn-pill btn-outline-primary" href="#inschrijven"
                >Bekijk de startdata<svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right ml-1"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline></svg
              ></a>
            </div>
          </div>
          <div
            class="col-md-6 bg-position-center bg-size-cover bg-secondary"
            :style="`min-height: 15rem; background-image: url(/${backgroundImage()});`"
          >
            <span class="split-section-arrow arrow-left"></span>
          </div>
        </div>
        <div class="mt-4">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/educatie">Educatie</a></li>
              <li class="breadcrumb-item">
                <a
                  :href="`/educatie/${course.sections[0].slug}`"
                  v-if="course.sections"
                  >{{ course.sections[0].name }}</a
                >
              </li>
              <li class="breadcrumb-item">
                <a :href="course.slug">{{ course.name }}</a>
              </li>
            </ol>
          </nav>
          <!--h1.page-title !{pageTitle}-->
          <!--span.d-block.mt-2.text-muted !{pageSubTitle}-->
          <hr class="mt-4" />
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2">
      <div class="row">
        <div class="col-xl-9 col-lg-8" id="description">
          <p v-html="course.description" class="lead"></p>
          <EditableBlock
            v-if="course.page"
            :blocks="blocks"
            :page_id="course.page.id"
            block_name="algemeen"
            type="internalblocks"
          />
          <EditableBlock
            v-if="course.page"
            :blocks="blocks"
            :page_id="course.page.id"
            block_name="doel"
            type="internalblocks"
          />
          <div v-if="course.quotes && course.quotes.length > 0" class="mt-5">
            <!-- Basic blockquote -->
            <blockquote class="blockquote">
              <p class="mb-0">
                {{ course.quotes[0].quote }}
              </p>
              <footer class="blockquote-footer">
                {{ course.quotes[0].author }}
              </footer>
            </blockquote>
          </div>

          <div class="row mt-5" id="programma">
            <div class="col-md-12">
              <!-- Featured products -->
              <div class="widget widget-featured-entries">
                <h3 class="widget-title">Programma</h3>
              </div>
              <!--end widget title inschrijven-->
            </div>
            <!--en col 12-->
          </div>
          <!--end row title-->
          <div class="row pt-3 mb-md-3">
            <div class="col-md-12">
              <div v-for="program in programs" :key="program.id">
                <div :key="item.id" v-for="item in program.items">
                  <h2 class="h4 pb-3">{{ item.name }}</h2>
                  <p class="" v-html="item.description"></p>
                  <hr class="mt-4 mb-4" />
                </div>
              </div>
            </div>
            <!--end left col 7 inschrijving-->
          </div>
          <!--end row inschrijving-->

          <div class="row" id="faq">
            <div class="col-md-12">
              <!-- Featured products -->
              <div class="widget widget-featured-entries">
                <h3 class="widget-title">Veelgestelde vragen</h3>
              </div>
              <!--end widget title inschrijven-->
            </div>
            <!--en col 12-->
          </div>
          <!--end row title-->
          <div class="row pt-3 mb-md-3">
            <div class="col-md-12 d-block d-md-none">
              <div class="d-block d-md-none accordion" role="tablist">
                <b-card
                  no-body
                  class="mb-4"
                  v-for="faq in all_faqs"
                  :key="faq.id"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <h3 class="accordion-heading">
                      <a role="button" v-b-toggle="`accordion-${faq.id}`">
                        {{ faq.question }}
                        <span class="accordion-indicator"
                          ><i data-feather="chevron-up"></i
                        ></span>
                      </a>
                    </h3>
                  </b-card-header>
                  <b-collapse
                    :id="`accordion-${faq.id}`"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text> </b-card-text>
                      {{ faq.answer }}
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
            <div class="col-md-12 d-none d-md-block">
              <div v-for="faq in all_faqs" :key="faq.id">
                <h2 class="h4 pb-3">{{ faq.question }}</h2>
                <p class="">
                  {{ faq.answer }}
                </p>
                <hr class="mt-4 mb-4" />
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end col-9-->
        <div class="col-xl-3 col-lg-4">
          <!-- Related articles sidebar-->
          <div class="" id="inschrijven">
            <!--side sign up-->
            <h3 class="widget-title">Startdata</h3>

            <!-- Actionable list group inside card -->
            <div v-if="runs.length == 0" class="card">
              <div class="card-body">
                <p class="">
                  <span>Cursusprijs €{{ course.price | toFloat }},-</span
                  ><br /><br />
                  Neem contact op indien geen data beschikbaar via 020 524 60 60
                  of edu@openstudio.nl
                </p>
              </div>
            </div>
            <div v-for="run in runs" :key="run.id" class="card mb-4">
              <div class="card-body">
                <div class="icon-box d-flex align-items-center text-center">
                  <div class="icon-box-icon my-3 mr-3">
                    <i data-feather="calendar"></i>
                  </div>
                  <!--end icon-->
                  <div class="icon-box-body">
                    <h3 class="h4 icon-box-title text-left">
                      Start
                      {{ moment(run.start_date).format("dddd D MMMM YYYY") }}
                    </h3>
                  </div>
                  <!--end iconbox body-->
                </div>
                <!--end icon box-->
                <!--<h5 class="card-title">Card title</h5>-->
                <p class="card-text font-size-sm text-muted">
                  {{ run.remarks }}
                </p>
              </div>
              <div class="list-group list-group-flush">
                <span
                  class="list-group-item list-group-item-action"
                  v-if="run.days_of_week_remark"
                  ><i
                    class="text-success mr-2"
                    data-feather="check"
                    style="width: 1rem; height: 1rem"
                  ></i
                  ><span>{{ run.days_of_week_remark }}</span></span
                >
                <span
                  v-if="run.holidays_remark"
                  class="list-group-item list-group-item-action"
                  ><i
                    class="text-warning mr-2"
                    data-feather="x"
                    style="width: 1rem; height: 1rem"
                  ></i
                  ><span>{{ run.holidays_remark }}</span></span
                >
                <span
                  class="list-group-item list-group-item-action"
                  v-if="run.number_of_lessons"
                  ><i
                    class="text-success mr-2"
                    data-feather="calendar"
                    style="width: 1rem; height: 1rem"
                  ></i
                  ><span></span>{{ run.number_of_lessons }} lesdagen</span
                >
                <span class="list-group-item list-group-item-action"
                  ><i
                    class="text-success mr-2"
                    data-feather="shopping-bag"
                    style="width: 1rem; height: 1rem"
                  ></i
                  ><span>&euro; {{ course.price | toFloat }}</span></span
                >
              </div>
              <div class="card-body">
                <a
                  :href="
                    '/educatie/c/' + course.slug + '/' + run.id + '/inschrijven'
                  "
                  class="btn btn-outline-success btn-block"
                >
                  Schrijf je nu in
                </a>
              </div>
            </div>
            <!--end date card sign up-->

            <!--infodag-->
            <!--<a
              class="d-block text-decoration-0 mt-4"
              href="/inloopdagen/"
              ng-show="ctrl.items.items"
            >
              <div class="bg-secondary">
                <div class="px-3 pt-4 text-center">
                  <h4 class="font-size-sm font-weight-normal pt-1 mb-2">
                    Aankomende
                  </h4>
                  <h4 class="h5 pb-2">Infodag</h4>
                  <div class="btn btn-primary btn-sm mb-3">Meer info</div>
                </div>
                <OpenDoorDayWidget size="small" /></div
            ></a>-->

            <InfodagWidget size="small" />
            <!--end infodag-->

            <!--side nav-->
            <div class="widget widget-links mt-4">
              <h3 class="widget-title">Gerelateerde Cursussen</h3>
              <ul>
                <li v-for="r in related" :key="r.id">
                  <a :href="r.slug"
                    ><i
                      class="widget-categories-indicator"
                      data-feather="chevron-right"
                    ></i
                    >{{ r.name }}</a
                  >
                </li>
              </ul>
              <a
                class="btn btn-outline-danger rounded mt-2"
                role="button"
                href="/agenda/"
                >Agenda</a
              >
            </div>
          </div>
        </div>
        <!-- Submit request-->
        <!-- Split section: Map + Contact form-->
        <ContactWidget
          :subject="`Aanvraag adviesgesprek ${this.course.name}`"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.os-header-img-container {
  min-height: 330px;
  max-height: 400px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  background-size: 100% auto;
}
#description p {
  font-size: inherit !important;
}
</style>

<script>
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import holidays from "@/constants";
import { pageStore } from "@/stores/page";
import ContactWidget from "@/components/ContactWidget.vue";
import EditableBlock from "@/components/EditableBlock.vue";
import InfodagWidget from "@/components/InfodagWidget.vue";

export default {
  name: "CourseView2EditableBlock",
  components: {
    InfodagWidget,
    ContactWidget,
    EditableBlock,
  },
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
  },
  data: function () {
    return {
      course: {},
      runs: [],
      programs: [],
      related: [],
      faqs: [],
      blocks: new Map(),
    };
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  computed: {
    cursus_vragen() {
      if (
        this.page_store.widgets &&
        this.page_store.widgets.get("cursus-vragen")
      ) {
        return this.page_store.widgets.get("cursus-vragen").content;
      }
      return "";
    },
    all_faqs() {
      return _.compact(_.concat(this.course.faqs, this.faqs));
    },
  },
  methods: {
    holidays(dates) {
      return dates
        .filter((d) => holidays[moment(d).format("YYYY-MM-DD")] != undefined)
        .map((d) => holidays[moment(d).format("YYYY-MM-DD")])
        .join(",");
    },
    weekDays(dates) {
      return _.uniq(
        dates.map((d) => moment(d.date).format("dddd")),
        (d) => d
      ).join("&");
    },
    fetchData() {
      var vue = this;
      axios
        .get("/api/courses/" + this.$route.params.slug + "/")
        .then((response) => {
          this.course = response.data;
          axios
            .get("/api/main/internalpages/" + this.course.page.id + "/")
            .then((response) => {
              this.blocks = new Map(
                response.data.blocks.map(function (b) {
                  return [b.name, b];
                })
              );
            });
          var relatedparams = {
            params: {
              category__slug: this.course.categories
                .map((c) => c.slug)
                .join(","),
              with_runs: true,
              no_page: true,
            },
          };
          axios.get("/api/courses/", relatedparams).then((response) => {
            this.related = response.data.filter((c) => c.id != this.course.id);
          });
        });
      var params = {
        params: {
          courses__slug: this.$route.params.slug,
          no_page: true,
        },
      };
      axios
        .get("/api/programs/", params)
        .then((response) => (this.programs = response.data));
      params = {
        params: {
          course__slug: this.$route.params.slug,
          no_page: true,
        },
      };
      axios.get("/api/runs/", params).then((response) => {
        this.runs = response.data;
        this.runs.forEach((run) => {
          run.shortDates = run.dates
            .map(function (d) {
              return vue.moment(d.date).format("DD/MM");
            })
            .join(", ");
        });
      });
      axios
        .get("/api/faqs/", { params: { everywhere: true, no_page: true } })
        .then((response) => {
          this.faqs = response.data;
        });
    },
    backgroundImage() {
      if (this.course && this.course.banner) {
        return `media/${this.course.banner}`;
      }
      return "media/uploads/edu/export-banner-tussenjaarfilm-creatief-960x500.png";
    },
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>
