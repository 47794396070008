<template>
  <form class="pt-4" novalidate @submit.prevent>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i data-feather="lock"></i></span>
      </div>
      <input
        class="form-control"
        type="password"
        v-bind:class="{ 'is-invalid': errors.password }"
        placeholder="Password"
        name="set-password"
        v-model="password"
        required
      />
      <div v-if="errors.password" class="invalid-feedback">
        {{ errors.password[0] }}
      </div>
    </div>
    <div class="form-group" v-if="errors.message">
      {{ errors.message }}
    </div>
    <div class="text-right mt-3">
      <button
        v-on:click="doSetPassword"
        v-bind:class="{ disabled: !enabled }"
        class="btn btn-primary"
        type="submit"
      >
        Opslaan
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { userStore } from "@/stores/user";

export default {
  name: "PasswordWidget",
  components: {},
  props: ["noredirect"],
  data: function () {
    return {
      enabled: true,
      errors: {},
      email: "",
      password: "",
    };
  },
  computed: {
    user() {
      return this.store.user;
    },
  },
  methods: {
    doSetPassword: function () {
      this.errors = {};
      var payload = {
        token: this.$route.query.token,
        password: this.password,
      };
      axios.post("/api/auth/set-password/", payload).then(
        () => {
          if(this.$route.query.r){
            window.location.href = `/${this.$route.query.r}`;
          } else{
            window.location.href = "/activate-account";
          }
        },
        (error) => {
          if (error.response) {
            this.errors = error.response.data;
          }
        }
      );
    },
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
};
</script>

<style scoped></style>
