<template>
  <!-- Off-canvas cart-->
  <div
    class="offcanvas offcanvas-reverse"
    id="offcanvas-cart"
  >
    <div
      class="offcanvas-header d-flex justify-content-between align-items-center"
    >
      <h3 class="offcanvas-title">Winkelwagen</h3>
      <button
        class="close"
        type="button"
        data-dismiss="offcanvas"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="offcanvas-body-inner">
        <!--<div class="text-right"><a class="text-danger btn-sm pr-0" href="#"><i class="mr-1" data-feather="x" style="width: .85rem; height: .85rem;"></i>winkelwagen legen</a></div>-->
        <div class="widget widget-featured-entries py-3">
          <!-- Number input -->
          <div
            v-if="cart.cart.rental_order_lines.length != 0"
            class="form-group row align-items-center pb-3"
          >
            <!--<div class="col-sm-12"><h6>{[cartCtrl.days]} Huurdag(en)</h6></div>-->
          </div>
          <div
            class="media"
            :key="item.id"
            v-for="item in cart.rental_order_lines"
          >
            <div class="featured-entry-thumb mr-3">
              <a
                class="thumb-container"
                :href="'/verhuur/artikel/' + item.article.slug"
              >
                <img
                  :src="'/media/' + item.article.image_small"
                  width="64"
                  alt="Product thumb"
                />
              </a>
              <span
                class="item-remove-btn"
                v-on:click="
                  item.count = 0;
                  cart.removeArticle(item.article);
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
            <div class="media-body">
              <h6 class="featured-entry-title">
                <a :href="'/verhuur/artikel/' + item.article.slug">
                  <span class="badge badge-pill badge-success">Huren</span>
                  {{ item.article.name }}
                </a>
              </h6>
              <p class="featured-entry-meta mb-0">
                &euro;{{ item.price_total | toFloat | toAmount }}
              </p>
              <p class="featured-entry-meta mb-1 small">
                Aantal: {{ item.count }}
              </p>
            </div>
          </div>
          <div
            class="media"
            :key="item.id"
            v-for="item in [
              ...cart.sales_order_lines,
              ...cart.occasion_order_lines,
            ]"
          >
            <div class="featured-entry-thumb mr-3">
              <a
                class="thumb-container"
                v-if="['SALE'].includes(item.article.type)"
                :href="'/verkoop/artikel/' + item.article.slug"
              >
                <img
                  :src="'/media/' + item.article.image_small"
                  width="64"
                  alt="Product thumb"
                />
              </a>
              <a
                class="thumb-container"
                v-if="item.article.type == 'OCCASION'"
                :href="'/occasions/artikel/' + item.article.slug"
              >
                <img
                  :src="'/media/' + item.article.image_small"
                  width="64"
                  alt="Product thumb"
                />
              </a>
              <span
                class="item-remove-btn"
                v-on:click="
                  item.count = 0;
                  cart.removeArticle(item.article);
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
            <div class="media-body">
              <h6 class="featured-entry-title">
                <a
                  v-if="['SALE'].includes(item.article.type)"
                  :href="'/verkoop/artikel/' + item.article.slug"
                >
                  <span class="badge badge-pill badge-danger">kopen</span>
                  {{ item.article.name }}
                </a>
                <a
                  v-if="item.article.type == 'OCCASION'"
                  :href="'/occasions/artikel/' + item.article.slug"
                >
                  <span class="badge badge-pill badge-warning">occasion</span>
                  {{ item.article.name }}
                </a>
              </h6>
              <p class="featured-entry-meta mb-0">
                &euro;{{ item.price_total | toFloat | toAmount }}
              </p>
              <p class="featured-entry-meta mb-1 small">
                Aantal: {{ item.count }}
              </p>
            </div>
          </div>
          <hr class="pb-3"/>
          <div v-if="cart.total_discount > 0" class="d-flex justify-content-between align-items-center">
            <div class="font-size-sm">
              <span class="mr-2">Klantkorting:</span>
              <span
                class="font-weight-semibold text-dark"
                >&euro;{{ cart.total_discount | toFloat | toAmount }}
                </span
              >
              <!--<span
                ng-show="cartCtrl.days > 10"
                class="font-weight-semibold text-dark"
                >prijs op aanvraag</span
              >-->
            </div>
            <!--<a class="btn btn-outline-secondary btn-sm" href="/offerte/">Wijzig details<i class="mr-n2" data-feather="chevron-right"></i></a>-->
          </div>
          <div class="d-flex justify-content-between align-items-center pb-3">
            <div class="font-size-sm">
              <span class="mr-2">Subtotaal:</span>
              <span
                class="font-weight-semibold text-dark"
                >&euro;{{ cart.total_articles | toFloat | toAmount }} ex
                btw</span
              >
              <!--<span
                ng-show="cartCtrl.days > 10"
                class="font-weight-semibold text-dark"
                >prijs op aanvraag</span
              >-->
            </div>
            <!--<a class="btn btn-outline-secondary btn-sm" href="/offerte/">Wijzig details<i class="mr-n2" data-feather="chevron-right"></i></a>-->
          </div>
          <a
            data-ng-hide="loginCtrl.user != null"
            id="checkout-button"
            class="btn btn-info btn-sm btn-block"
            @click="doCheckout"
            ><i class="mr-1" data-feather="credit-card"></i>Bestellen</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cartStore } from "@/stores/cart";
import { checkoutStore } from "@/stores/checkout";


export default {
  name: "CartOffcanvas",
  props: {},
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
    toAmount: function (value) {
      if (!value) {
        return " ...";
      }
      return parseFloat(value.toFixed(2)).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
      });
    },
  },
  computed: {
  },
  methods: {
    doCheckout() {
      //this.checkout.clear()
      location="/offerte/"
    },
  },
  setup() {
    const cart = cartStore();
    const checkout = checkoutStore();
    cart.fetchCart();
    return {
      cart,
      checkout,
    };
  },
};
</script>

<style scoped>
.thumb-container {
  display: flex !important;
  align-items: center;
  height: 75px;
  margin-top: 4px;
}
</style>
