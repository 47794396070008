const holidays = {
  "2019-01-01": "Nieuwjaarsdag",
  "2019-04-21": "Eerste paasdag",
  "2019-04-22": "Tweede paasdag",
  "2019-04-27": "Koningsdag",
  "2019-05-30": "Hemelvaart",
  "2019-06-09": "Eerste Pinksterdag",
  "2019-06-10": "Tweede Pinksterdag",
  "2019-12-25": "Eerste Kerstdag",
  "2019-12-26": "Tweede Kerstdag",
  "2020-01-01": "Nieuwjaarsdag",
  "2020-04-12": "Eerste paasdag",
  "2020-04-13": "Tweede paasdag",
  "2020-04-27": "Koningsdag",
  "2020-05-05": "Bevrijdingsdag",
  "2020-05-21": "Hemelvaart",
  "2020-05-31": "Eerste Pinksterdag",
  "2020-06-01": "Tweede Pinksterdag",
  "2020-12-25": "Eerste Kerstdag",
  "2020-12-26": "Tweede Kerstdag",
  "2021-01-01": "Nieuwjaarsdag",
  "2021-04-04": "Eerste paasdag",
  "2021-04-05": "Tweede paasdag",
  "2021-04-27": "Koningsdag",
  "2021-05-13": "Hemelvaart",
  "2021-05-23": "Eerste Pinksterdag",
  "2021-05-24": "Tweede Pinksterdag",
  "2021-12-25": "Eerste Kerstdag",
  "2021-12-26": "Tweede Kerstdag",
  "2022-01-01": "Nieuwjaarsdag",
  "2022-04-17": "Eerste paasdag",
  "2022-04-18": "Tweede paasdag",
  "2022-04-27": "Koningsdag",
  "2022-05-26": "Hemelvaart",
  "2022-06-05": "Eerste Pinksterdag",
  "2022-06-06": "Tweede Pinksterdag",
  "2022-12-25": "Eerste Kerstdag",
  "2022-12-26": "Tweede Kerstdag",
  "2023-01-01": "Nieuwjaarsdag",
  "2023-04-09": "Eerste paasdag",
  "2023-04-10": "Tweede paasdag",
  "2023-04-27": "Koningsdag",
  "2023-05-18": "Hemelvaart",
  "2023-05-28": "Eerste Pinksterdag",
  "2023-05-29": "Tweede Pinksterdag",
  "2023-12-25": "Eerste Kerstdag",
  "2023-12-26": "Tweede Kerstdag",
  "2024-01-01": "Nieuwjaarsdag",
  "2024-03-31": "Eerste paasdag",
  "2024-04-01": "Tweede paasdag",
  "2024-04-27": "Koningsdag",
  "2024-05-09": "Hemelvaart",
  "2024-05-19": "Eerste Pinksterdag",
  "2024-05-20": "Tweede Pinksterdag",
  "2024-12-25": "Eerste Kerstdag",
  "2024-12-26": "Tweede Kerstdag",
  "2025-01-01": "Nieuwjaarsdag",
  "2025-04-20": "Eerste paasdag",
  "2025-04-21": "Tweede paasdag",
  "2025-04-26": "Koningsdag",
  "2025-05-05": "Bevrijdingsdag",
  "2025-05-29": "Hemelvaart",
  "2025-06-08": "Eerste Pinksterdag",
  "2025-06-09": "Tweede Pinksterdag",
  "2025-12-25": "Eerste Kerstdag",
  "2025-12-26": "Tweede Kerstdag",
  "2026-01-01": "Nieuwjaarsdag",
  "2026-04-05": "Eerste paasdag",
  "2026-04-06": "Tweede paasdag",
  "2026-04-27": "Koningsdag",
  "2026-05-14": "Hemelvaart",
  "2026-05-24": "Eerste Pinksterdag",
  "2026-05-25": "Tweede Pinksterdag",
  "2026-12-25": "Eerste Kerstdag",
  "2026-12-26": "Tweede Kerstdag",
  "2027-01-01": "Nieuwjaarsdag",
  "2027-03-28": "Eerste paasdag",
  "2027-03-29": "Tweede paasdag",
  "2027-04-27": "Koningsdag",
  "2027-05-06": "Hemelvaart",
  "2027-05-16": "Eerste Pinksterdag",
  "2027-05-17": "Tweede Pinksterdag",
  "2027-12-25": "Eerste Kerstdag",
  "2027-12-26": "Tweede Kerstdag",
  "2028-01-01": "Nieuwjaarsdag",
  "2028-04-16": "Eerste paasdag",
  "2028-04-17": "Tweede paasdag",
  "2028-04-27": "Koningsdag",
  "2028-05-25": "Hemelvaart",
  "2028-06-04": "Eerste Pinksterdag",
  "2028-06-05": "Tweede Pinksterdag",
  "2028-12-25": "Eerste Kerstdag",
  "2028-12-26": "Tweede Kerstdag",
  "2029-01-01": "Nieuwjaarsdag",
  "2029-04-01": "Eerste paasdag",
  "2029-04-02": "Tweede paasdag",
  "2029-04-27": "Koningsdag",
  "2029-05-10": "Hemelvaart",
  "2029-05-20": "Eerste Pinksterdag",
  "2029-05-21": "Tweede Pinksterdag",
  "2029-12-25": "Eerste Kerstdag",
  "2029-12-26": "Tweede Kerstdag",
  "2030-01-01": "Nieuwjaarsdag",
  "2030-04-21": "Eerste paasdag",
  "2030-04-22": "Tweede paasdag",
  "2030-04-27": "Koningsdag",
  "2030-05-05": "Bevrijdingsdag",
  "2030-05-30": "Hemelvaart",
  "2030-06-09": "Eerste Pinksterdag",
  "2030-06-10": "Tweede Pinksterdag",
  "2030-12-25": "Eerste Kerstdag",
  "2030-12-26": "Tweede Kerstdag",
  "2031-01-01": "Nieuwjaarsdag",
  "2031-04-13": "Eerste paasdag",
  "2031-04-14": "Tweede paasdag",
  "2031-04-26": "Koningsdag",
  "2031-05-22": "Hemelvaart",
  "2031-06-01": "Eerste Pinksterdag",
  "2031-06-02": "Tweede Pinksterdag",
  "2031-12-25": "Eerste Kerstdag",
  "2031-12-26": "Tweede Kerstdag",
  "2032-01-01": "Nieuwjaarsdag",
  "2032-03-28": "Eerste paasdag",
  "2032-03-29": "Tweede paasdag",
  "2032-04-27": "Koningsdag",
  "2032-05-06": "Hemelvaart",
  "2032-05-16": "Eerste Pinksterdag",
  "2032-05-17": "Tweede Pinksterdag",
  "2032-12-25": "Eerste Kerstdag",
  "2032-12-26": "Tweede Kerstdag",
  "2033-01-01": "Nieuwjaarsdag",
  "2033-04-17": "Eerste paasdag",
  "2033-04-18": "Tweede paasdag",
  "2033-04-27": "Koningsdag",
  "2033-05-26": "Hemelvaart",
  "2033-06-05": "Eerste Pinksterdag",
  "2033-06-06": "Tweede Pinksterdag",
  "2033-12-25": "Eerste Kerstdag",
  "2033-12-26": "Tweede Kerstdag",
  "2034-01-01": "Nieuwjaarsdag",
  "2034-04-09": "Eerste paasdag",
  "2034-04-10": "Tweede paasdag",
  "2034-04-27": "Koningsdag",
  "2034-05-18": "Hemelvaart",
  "2034-05-28": "Eerste Pinksterdag",
  "2034-05-29": "Tweede Pinksterdag",
  "2034-12-25": "Eerste Kerstdag",
  "2034-12-26": "Tweede Kerstdag",
  "2035-01-01": "Nieuwjaarsdag",
  "2035-03-25": "Eerste paasdag",
  "2035-03-26": "Tweede paasdag",
  "2035-04-27": "Koningsdag",
  "2035-05-03": "Hemelvaart",
  "2035-05-05": "Bevrijdingsdag",
  "2035-05-13": "Eerste Pinksterdag",
  "2035-05-14": "Tweede Pinksterdag",
  "2035-12-25": "Eerste Kerstdag",
  "2035-12-26": "Tweede Kerstdag",
  "2036-01-01": "Nieuwjaarsdag",
  "2036-04-13": "Eerste paasdag",
  "2036-04-14": "Tweede paasdag",
  "2036-04-26": "Koningsdag",
  "2036-05-22": "Hemelvaart",
  "2036-06-01": "Eerste Pinksterdag",
  "2036-06-02": "Tweede Pinksterdag",
  "2036-12-25": "Eerste Kerstdag",
  "2036-12-26": "Tweede Kerstdag",
  "2037-01-01": "Nieuwjaarsdag",
  "2037-04-05": "Eerste paasdag",
  "2037-04-06": "Tweede paasdag",
  "2037-04-27": "Koningsdag",
  "2037-05-14": "Hemelvaart",
  "2037-05-24": "Eerste Pinksterdag",
  "2037-05-25": "Tweede Pinksterdag",
  "2037-12-25": "Eerste Kerstdag",
  "2037-12-26": "Tweede Kerstdag",
  "2038-01-01": "Nieuwjaarsdag",
  "2038-04-25": "Eerste paasdag",
  "2038-04-26": "Tweede paasdag",
  "2038-04-27": "Koningsdag",
  "2038-06-03": "Hemelvaart",
  "2038-06-13": "Eerste Pinksterdag",
  "2038-06-14": "Tweede Pinksterdag",
  "2038-12-25": "Eerste Kerstdag",
  "2038-12-26": "Tweede Kerstdag",
  "2039-01-01": "Nieuwjaarsdag",
  "2039-04-10": "Eerste paasdag",
  "2039-04-11": "Tweede paasdag",
  "2039-04-27": "Koningsdag",
  "2039-05-19": "Hemelvaart",
  "2039-05-29": "Eerste Pinksterdag",
  "2039-05-30": "Tweede Pinksterdag",
  "2039-12-25": "Eerste Kerstdag",
  "2039-12-26": "Tweede Kerstdag",
  "2040-01-01": "Nieuwjaarsdag",
  "2040-04-01": "Eerste paasdag",
  "2040-04-02": "Tweede paasdag",
  "2040-04-27": "Koningsdag",
  "2040-05-05": "Bevrijdingsdag",
  "2040-05-10": "Hemelvaart",
  "2040-05-20": "Eerste Pinksterdag",
  "2040-05-21": "Tweede Pinksterdag",
  "2040-12-25": "Eerste Kerstdag",
  "2040-12-26": "Tweede Kerstdag",
  "2041-01-01": "Nieuwjaarsdag",
  "2041-04-21": "Eerste paasdag",
  "2041-04-22": "Tweede paasdag",
  "2041-04-27": "Koningsdag",
  "2041-05-30": "Hemelvaart",
  "2041-06-09": "Eerste Pinksterdag",
  "2041-06-10": "Tweede Pinksterdag",
  "2041-12-25": "Eerste Kerstdag",
  "2041-12-26": "Tweede Kerstdag",
  "2042-01-01": "Nieuwjaarsdag",
  "2042-04-06": "Eerste paasdag",
  "2042-04-07": "Tweede paasdag",
  "2042-04-26": "Koningsdag",
  "2042-05-15": "Hemelvaart",
  "2042-05-25": "Eerste Pinksterdag",
  "2042-05-26": "Tweede Pinksterdag",
  "2042-12-25": "Eerste Kerstdag",
  "2042-12-26": "Tweede Kerstdag",
  "2043-01-01": "Nieuwjaarsdag",
  "2043-03-29": "Eerste paasdag",
  "2043-03-30": "Tweede paasdag",
  "2043-04-27": "Koningsdag",
  "2043-05-07": "Hemelvaart",
  "2043-05-17": "Eerste Pinksterdag",
  "2043-05-18": "Tweede Pinksterdag",
  "2043-12-25": "Eerste Kerstdag",
  "2043-12-26": "Tweede Kerstdag",
  "2044-01-01": "Nieuwjaarsdag",
  "2044-04-17": "Eerste paasdag",
  "2044-04-18": "Tweede paasdag",
  "2044-04-27": "Koningsdag",
  "2044-05-26": "Hemelvaart",
  "2044-06-05": "Eerste Pinksterdag",
  "2044-06-06": "Tweede Pinksterdag",
  "2044-12-25": "Eerste Kerstdag",
  "2044-12-26": "Tweede Kerstdag",
  "2045-01-01": "Nieuwjaarsdag",
  "2045-04-09": "Eerste paasdag",
  "2045-04-10": "Tweede paasdag",
  "2045-04-27": "Koningsdag",
  "2045-05-05": "Bevrijdingsdag",
  "2045-05-18": "Hemelvaart",
  "2045-05-28": "Eerste Pinksterdag",
  "2045-05-29": "Tweede Pinksterdag",
  "2045-12-25": "Eerste Kerstdag",
  "2045-12-26": "Tweede Kerstdag",
  "2046-01-01": "Nieuwjaarsdag",
  "2046-03-25": "Eerste paasdag",
  "2046-03-26": "Tweede paasdag",
  "2046-04-27": "Koningsdag",
  "2046-05-03": "Hemelvaart",
  "2046-05-13": "Eerste Pinksterdag",
  "2046-05-14": "Tweede Pinksterdag",
  "2046-12-25": "Eerste Kerstdag",
  "2046-12-26": "Tweede Kerstdag",
  "2047-01-01": "Nieuwjaarsdag",
  "2047-04-14": "Eerste paasdag",
  "2047-04-15": "Tweede paasdag",
  "2047-04-27": "Koningsdag",
  "2047-05-23": "Hemelvaart",
  "2047-06-02": "Eerste Pinksterdag",
  "2047-06-03": "Tweede Pinksterdag",
  "2047-12-25": "Eerste Kerstdag",
  "2047-12-26": "Tweede Kerstdag",
  "2048-01-01": "Nieuwjaarsdag",
  "2048-04-05": "Eerste paasdag",
  "2048-04-06": "Tweede paasdag",
  "2048-04-27": "Koningsdag",
  "2048-05-14": "Hemelvaart",
  "2048-05-24": "Eerste Pinksterdag",
  "2048-05-25": "Tweede Pinksterdag",
  "2048-12-25": "Eerste Kerstdag",
  "2048-12-26": "Tweede Kerstdag",
  "2049-01-01": "Nieuwjaarsdag",
  "2049-04-18": "Eerste paasdag",
  "2049-04-19": "Tweede paasdag",
  "2049-04-27": "Koningsdag",
  "2049-05-27": "Hemelvaart",
  "2049-06-06": "Eerste Pinksterdag",
  "2049-06-07": "Tweede Pinksterdag",
  "2049-12-25": "Eerste Kerstdag",
  "2049-12-26": "Tweede Kerstdag",
  "2050-01-01": "Nieuwjaarsdag",
  "2050-04-10": "Eerste paasdag",
  "2050-04-11": "Tweede paasdag",
  "2050-04-27": "Koningsdag",
  "2050-05-05": "Bevrijdingsdag",
  "2050-05-19": "Hemelvaart",
  "2050-05-29": "Eerste Pinksterdag",
  "2050-05-30": "Tweede Pinksterdag",
  "2050-12-25": "Eerste Kerstdag",
  "2050-12-26": "Tweede Kerstdag",
  "2051-01-01": "Nieuwjaarsdag",
  "2051-04-02": "Eerste paasdag",
  "2051-04-03": "Tweede paasdag",
  "2051-04-27": "Koningsdag",
  "2051-05-11": "Hemelvaart",
  "2051-05-21": "Eerste Pinksterdag",
  "2051-05-22": "Tweede Pinksterdag",
  "2051-12-25": "Eerste Kerstdag",
  "2051-12-26": "Tweede Kerstdag",
  "2052-01-01": "Nieuwjaarsdag",
  "2052-04-21": "Eerste paasdag",
  "2052-04-22": "Tweede paasdag",
  "2052-04-27": "Koningsdag",
  "2052-05-30": "Hemelvaart",
  "2052-06-09": "Eerste Pinksterdag",
  "2052-06-10": "Tweede Pinksterdag",
  "2052-12-25": "Eerste Kerstdag",
  "2052-12-26": "Tweede Kerstdag",
  "2053-01-01": "Nieuwjaarsdag",
  "2053-04-06": "Eerste paasdag",
  "2053-04-07": "Tweede paasdag",
  "2053-04-26": "Koningsdag",
  "2053-05-15": "Hemelvaart",
  "2053-05-25": "Eerste Pinksterdag",
  "2053-05-26": "Tweede Pinksterdag",
  "2053-12-25": "Eerste Kerstdag",
  "2053-12-26": "Tweede Kerstdag",
  "2054-01-01": "Nieuwjaarsdag",
  "2054-03-29": "Eerste paasdag",
  "2054-03-30": "Tweede paasdag",
  "2054-04-27": "Koningsdag",
  "2054-05-07": "Hemelvaart",
  "2054-05-17": "Eerste Pinksterdag",
  "2054-05-18": "Tweede Pinksterdag",
  "2054-12-25": "Eerste Kerstdag",
  "2054-12-26": "Tweede Kerstdag",
  "2055-01-01": "Nieuwjaarsdag",
  "2055-04-18": "Eerste paasdag",
  "2055-04-19": "Tweede paasdag",
  "2055-04-27": "Koningsdag",
  "2055-05-05": "Bevrijdingsdag",
  "2055-05-27": "Hemelvaart",
  "2055-06-06": "Eerste Pinksterdag",
  "2055-06-07": "Tweede Pinksterdag",
  "2055-12-25": "Eerste Kerstdag",
  "2055-12-26": "Tweede Kerstdag",
  "2056-01-01": "Nieuwjaarsdag",
  "2056-04-02": "Eerste paasdag",
  "2056-04-03": "Tweede paasdag",
  "2056-04-27": "Koningsdag",
  "2056-05-11": "Hemelvaart",
  "2056-05-21": "Eerste Pinksterdag",
  "2056-05-22": "Tweede Pinksterdag",
  "2056-12-25": "Eerste Kerstdag",
  "2056-12-26": "Tweede Kerstdag",
  "2057-01-01": "Nieuwjaarsdag",
  "2057-04-22": "Eerste paasdag",
  "2057-04-23": "Tweede paasdag",
  "2057-04-27": "Koningsdag",
  "2057-05-31": "Hemelvaart",
  "2057-06-10": "Eerste Pinksterdag",
  "2057-06-11": "Tweede Pinksterdag",
  "2057-12-25": "Eerste Kerstdag",
  "2057-12-26": "Tweede Kerstdag",
  "2058-01-01": "Nieuwjaarsdag",
  "2058-04-14": "Eerste paasdag",
  "2058-04-15": "Tweede paasdag",
  "2058-04-27": "Koningsdag",
  "2058-05-23": "Hemelvaart",
  "2058-06-02": "Eerste Pinksterdag",
  "2058-06-03": "Tweede Pinksterdag",
  "2058-12-25": "Eerste Kerstdag",
  "2058-12-26": "Tweede Kerstdag",
  "2059-01-01": "Nieuwjaarsdag",
  "2059-03-30": "Eerste paasdag",
  "2059-03-31": "Tweede paasdag",
  "2059-04-26": "Koningsdag",
  "2059-05-08": "Hemelvaart",
  "2059-05-18": "Eerste Pinksterdag",
  "2059-05-19": "Tweede Pinksterdag",
  "2059-12-25": "Eerste Kerstdag",
  "2059-12-26": "Tweede Kerstdag",
};

export default holidays;
