import axios from "axios";
import { defineStore } from "pinia";
import _ from "lodash";
import Vue from "vue";
import moment from "moment";

const slot_description = function (slot) {
  console.log(slot);
  if (slot == 0) return "09:00 - 10:00";
  if (slot == 1) return "10:00 - 13:00";
  if (slot == 2) return "13:00 - 16:00";
  if (slot == 3) return "16:00 - 17:00";
};


const post_cart = function(payload) {
    var headers = {
      "X-CSRFToken": Vue.$cookies.get("csrftoken"),
    };
    return axios.post("/api/shop/pcart/", payload, {headers})
}

const post_lines = function(payload) {
    var headers = {
      "X-CSRFToken": Vue.$cookies.get("csrftoken"),
    };
    return axios.post("/api/shop/pcart-lines/", payload, {headers})
}

const delete_line = function(lineid) {
    var headers = {
      "X-CSRFToken": Vue.$cookies.get("csrftoken"),
    };
    return axios.delete(`/api/shop/pcart-lines/${lineid}/`, {headers})
}

const delete_cart = function() {
    var headers = {
      "X-CSRFToken": Vue.$cookies.get("csrftoken"),
    };
    return axios.delete(`/api/shop/pcart/`, {headers})
}

export const cartStore = defineStore("cart", {
  state: () => ({
    cart: {
      lines: [],
      rental_order_lines: [],
      sales_order_lines: [],
      occasion_order_lines: [],
      count: -1,
      start_date: null,
      end_date: null,
      pickup_slot: null,
      return_slot: null,
      rental_btw: 0,
      sales_btw: 0,
      borg: 0,
      reg: 0,
      discount: 0,
      transport: 0,
      loading: 0,
      sales_shipping: false,
      sales_totals: {
        excl_btw: 0,
      },
      rental_totals: {
        excl_btw: 0,
      },
      rental_total_price: 0,
      sales_total_price: 0,
    },
  }),
  getters: {
    pickup_slot_description() {
      return slot_description(this.cart.pickup_slot);
    },
    return_slot_description() {
      return slot_description(this.cart.return_slot);
    },
    total_transport() {
      return this.cart.transport;
    },
    total_articles(state) {
      return (
        state.cart.rental_total_price +
        state.cart.sales_total_price -
        state.cart.reg -
        state.cart.transport
      );
    },
    rental_order_lines(state) {
      return _.filter(
        state.cart.lines,
        (line) => line.article.article_type == "RENTAL"
      );
    },
    sales_order_lines(state) {
      var slines = _.filter(
        state.cart.lines,
        (line) => line.article.article_type == "SALE"
      );
      console.log(state.cart.lines)
      console.log(slines)
      return slines;
    },
    occasion_order_lines(state) {
      return _.filter(
        state.cart.lines,
        (line) => line.article.article_type == "OCCASION"
      );
    },
    total_in(state) {
      return (
        state.cart.rental_total_price +
        state.cart.sales_total_price +
        state.cart.rental_btw +
        state.cart.sales_btw
      );
    },
    btw(state) {
      return state.cart.rental_btw + state.cart.sales_btw;
    },
  },
  mutations: {
    lines(state, lines) {
      state.lines = lines;
    },
  },
  actions: {
    fetchPrices() {
      let payload = {
        start_date: this.cart.start_date,
        end_date: this.cart.end_date,
        pickup_slot: { slot: this.cart.pickup_slot, custom: false },
        return_slot: { slot: this.cart.return_slot, custom: false },
        lines: this.cart.lines,
        include_shipping_costs: this.cart.sales_shipping,
      };
      var headers = {
        "X-CSRFToken": Vue.$cookies.get("csrftoken"),
      };
      axios.post("/api/shop/prices/", payload, { headers }).then((response) => {
        let _lines = [
          ...response.data.rental_lines,
          ...response.data.sales_lines,
        ];
        _.forEach(_lines, (p) => {
          let line = _.find(this.cart.lines, (line) => p.id == line.article_id);
          if (p.count == 1) {
            line.price_total = p.price * line.count;
            line.btw_total = p.btw * line.count;
          } else {
            line.price_total = p.price;
            line.btw_total = p.btw;
          }
        });
        this.cart.lines = [...this.cart.lines];
        this.cart.rental_total_price = response.data.rental_totals.excl_btw;
        this.cart.sales_total_price = response.data.sales_totals.excl_btw;
        this.cart.rental_btw = response.data.rental_totals.btw;
        this.cart.sales_btw = response.data.sales_totals.btw;
        this.cart.borg = response.data.borg.totaal;
        this.cart.reg = response.data.reg.totaal;
        this.cart.discount = response.data.discount.totaal;
        this.cart.transport = response.data.transport.totaal;
        this.cart.loading = false;
      });
    },
    setPeriod(start_date, end_date, pickup_slot, return_slot) {
      var payload = {
        start_date: moment(start_date || this.cart.start_date).format(moment.HTML5_FMT.DATE),
        end_date: moment(end_date || this.cart.end_date).format(moment.HTML5_FMT.DATE),
        pickup_slot: pickup_slot || this.cart.pickup_slot,
        return_slot: return_slot || this.cart.return_slot,
        sales_shipping: this.cart.sales_shipping,
      }
      console.log(payload)
      return post_cart(payload).then((response) => {
        this.$patch({
          cart: response.data,
        });
        this.fetchPrices();
      });
    },
    fetchCart() {
      return axios.get("/api/shop/pcart/").then((response) => {
        this.$patch({
          cart: response.data,
        });
        this.fetchPrices();
      });
    },
    setCount(art, count) {
      let payload = {
        article_id: art.id,
        count: count,
      };
      post_lines(payload).then(() => {
        this.fetchCart();
      });
    },
    addArticle(art) {
      let cur = _.find(this.cart.lines, (o) => o.article_id == art.id);
      let payload = {
        article_id: art.id,
        count: 1,
      };
      if (cur) {
        payload.count = cur.count + 1;
      }
      post_lines(payload).then(() => {
        this.fetchCart();
      });
    },
    clear() {
      return delete_cart().then((response) => {
        this.$patch({
          cart: response.data,
        });
        this.fetchPrices();
      });
    },
    removeArticle(art) {
      let cur = _.find(this.cart.lines, (o) => o.article_id == art.id);
      if (cur) {
        delete_line(cur.id).then(() => {
          this.fetchCart();
        });
      }
    },
  },
});
