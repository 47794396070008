<template>
  <div class="product-card mb-4">
    <div class="product-thumb" style="height: 250px">
      <a
        target="_self"
        class="product-thumb-link"
        style="display: flex; align-items: center; height: 250px"
        :href="'/' + tl() + '/artikel/' + article.slug"
      >
        <img
          style="
            max-width: 80%;
            max-height: 100%;
            margin: 0 auto;
            display: block;
          "
          :src="'/media/' + article.image_medium"
          alt="Product"
        />
      </a>
    </div>
    <div class="product-card-body text-center" v-if="article && article.price">
      <a class="product-meta" href="#">{{ article.brand }}</a>
      <span class="product-card-title">
        <span>
          <span
            type="button"
            title="Op voorraad"
            v-if="article.stock_status == 'in_stock'"
            class="text-success"
            >{{dots(article)}}</span
          >
          <span
            type="button"
            title="Op voorraad bij leverancier"
            v-else-if="article.stock_status == 'ext_stock'"
            class="text-warning"
            >{{dots(article)}}</span
          >
          <span
            type="button"
            title="Niet op voorraad"
            v-else-if="article.stock_status == 'out_of_stock'"
            class="text-danger"
            >{{dots(article)}}</span
          >
          <span type="button" title="Voorraad onbekend" v-else class="">●</span>
        </span>
        <a :href="'/' + tl() + '/artikel/' + article.slug" class="text-dark">
          {{ article.name }} </a
        ><br />
      </span>
      <span v-if="!article.special_price" class="text-primary"
        >&euro;{{ article.price | toFloat | toAmount }}</span
      >
      <span v-else class="text-primary"
        ><del>&euro;{{ article.price | toFloat | toAmount }}</del> &euro;{{
          article.special_price | toFloat | toAmount
        }}</span
      >
    </div>
    <div class="product-card-body body-hidden">
      <button
        class="btn btn-primary btn-sm btn-block"
        type="button"
        data-toggle="offcanvas"
        data-target="#offcanvas-cart"
        v-on:click="addToBasket(article)"
      >
        Toevoegen aan winkelwagen
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesArticle",
  props: ["article", "cart"],
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
    toAmount: function (value) {
      return parseFloat(value.toFixed(2)).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
      });
    },
  },
  methods: {
    tl() {
      if (["SALE"].includes(this.article.type)) {
        return "verkoop";
      }
      return "occasions";
    },
    dots(article){
      if(article.source == "SAM" && article.article_type == "SALE"){
        return "●●"
      }
      return "●"
    },
    addToBasket(article) {
      this.cart.addArticle(article);
      window.$("#add-to-cart-toast").toast("dispose");
      window.$("#add-to-cart-toast").toast("show");
    },
  },
};
</script>

<style scoped>
.product-card-title {
  min-height: 60px;
}
</style>
