<template>
  <div class="container">
    <div class="row pt-5">
      <!--
    <div class="col-xl-3 col-lg-4">
      <a
        class="gallery-item mb-grid-gutter mx-auto"
        href="#"
        style="max-width: 18.75rem"
        ><img src="img/account/customer-lg.jpg" alt="Daniel Adams" />
        <div class="gallery-caption">
          <div class="gallery-indicator">
            <i class="gallery-indicator-icon" data-feather="refresh-ccw"></i>
          </div>
          Change profile picture
        </div></a
      >
      <div class="d-none d-lg-block">
        <h6 class="font-size-sm mb-3 pb-2 border-bottom">Technical support</h6>
        <ul class="list-unstyled">
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="mail"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            ><a class="nav-link-inline" href="mailto:support@example.com"
              >support@example.com</a
            >
          </li>
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="phone"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            ><a class="nav-link-inline" href="tel:+100331697720"
              >+1 00 33 169 7720</a
            >
          </li>
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="clock"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            >1-2 business days
          </li>
        </ul>
        <div class="pt-2">
          <a
            class="btn btn-outline-secondary btn-sm btn-block"
            href="account-tickets.html"
            ><i class="mr-1" data-feather="tag"></i>My tickets (1)</a
          ><a
            class="btn btn-success btn-sm btn-block"
            href="account-tickets.html"
            data-toggle="modal"
            data-target="#open-ticket"
            >Submit new ticket</a
          >
        </div>
      </div>
    </div>-->
      <!-- Main content-->
      <div v-if="user_store.user" class="col-12">
        <!-- Customer details-->
        <div
          v-if="!email"
          class="d-flex flex-wrap justify-content-between pb-4"
        >
          <div class="pt-3 mr-3">
            <h3 class="mb-0">
              {{ user_store.user.first_name }} {{ user_store.user.last_name }}
            </h3>
            <span class="font-size-sm text-warning">{{
              user_store.user.email
            }}</span>
          </div>
          <div class="pt-3">
            <a class="btn btn-outline-primary btn-sm" href="/logout"
              ><i class="mr-1" data-feather="log-out"></i>Uitloggen</a
            >
          </div>
        </div>
        <div v-else class="d-flex flex-wrap justify-content-between pb-4">
          <div class="pt-3 mr-3">
            <span class="font-size-sm text-warning"
              >factuur voor {{ email }}</span
            >
          </div>
        </div>
        <!--<ul class="list-unstyled border p-3 mb-4">
          <li class="pb-1">
            <span class="opacity-80">&ndash; Joined:</span
            ><span class="font-weight-semibold ml-1">Jan 13, 2018</span>
          </li>
          <li class="pb-1">
            <span class="opacity-80">&ndash; Total orders:</span
            ><span class="font-weight-semibold ml-1">15</span>
          </li>
          <li class="pb-1">
            <span class="opacity-80">&ndash; Total spent:</span
            ><span class="font-weight-semibold ml-1">$5,864</span>
          </li>
          <li>
            <span class="opacity-80">&ndash; Reward points:</span
            ><span class="font-weight-semibold ml-1">586</span>
          </li>
        </ul>-->
        <!-- Navigation (visible sm-up)-->
        <ul class="nav nav-tabs d-none d-sm-flex">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: show == 'orders' }"
              href="/account"
              ><i data-feather="shopping-bag"></i>&nbsp;Facturen
              <!--<span
              class="badge badge-pill badge-secondary bg-0 border ml-2"
              ><span class="text-primary">1</span></span
            >--></a
            >
          </li>
          <!--<li class="nav-item">
          <a class="nav-link" href="account-wishlist.html"
            ><i data-feather="heart"></i>&nbsp;Wishlist<span
              class="badge badge-pill badge-secondary bg-0 border ml-2"
              ><span class="text-primary">3</span></span
            ></a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle active"
            href="#"
            data-toggle="dropdown"
            ><i data-feather="settings"></i>&nbsp;Account settings</a
          >
          <div class="dropdown-menu">
            <a class="dropdown-item active" href="account-profile.html"
              >Profile info</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="account-address.html">Addresses</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="account-payment.html"
              >Payment methods</a
            >
          </div>
        </li>-->
        </ul>
        <!-- Navigation (visible sm-down)-->
        <div class="d-sm-none pb-4">
          <button
            class="btn btn-primary btn-block mb-2"
            type="button"
            data-toggle="collapse"
            data-target="#account-menu"
          >
            <i class="mr-2" data-feather="menu"></i>Menu
          </button>
          <div class="collapse" id="account-menu">
            <div class="list-group">
              <a
                class="list-group-item list-group-item-action"
                :class="{ active: show == 'orders' }"
                href="/account"
                ><i
                  class="mr-2"
                  data-feather="shopping-bag"
                  style="width: 1rem; height: 1rem"
                ></i
                >Facturen
                <!--<span
                class="badge badge-pill badge-secondary bg-0 border ml-2"
                ><span class="text-primary">1</span></span
              >-->
              </a>
              <!--<a
              class="list-group-item list-group-item-action"
              href="account-wishlist.html"
              ><i
                class="mr-2"
                data-feather="heart"
                style="width: 1rem; height: 1rem"
              ></i
              >Wishlist<span
                class="badge badge-pill badge-secondary bg-0 border ml-2"
                ><span class="text-primary">3</span></span
              ></a
            ><a
              class="list-group-item list-group-item-action active"
              href="account-profile.html"
              ><i
                class="mr-2"
                data-feather="user"
                style="width: 1rem; height: 1rem"
              ></i
              >Profile info</a
            ><a
              class="list-group-item list-group-item-action"
              href="account-address.html"
              ><i
                class="mr-2"
                data-feather="map-pin"
                style="width: 1rem; height: 1rem"
              ></i
              >Addresses</a
            ><a
              class="list-group-item list-group-item-action"
              href="account-payment.html"
              ><i
                class="mr-2"
                data-feather="credit-card"
                style="width: 1rem; height: 1rem"
              ></i
              >Payment methods</a
            >-->
            </div>
          </div>
        </div>
        <!-- Orders table-->
        <div v-if="show == 'orders'" class="mb-5 pb-5">
          <!--<div class="d-flex justify-content-end pb-3">
            <div class="form-inline">
              <label class="mr-3" for="order-sort">Sort Orders</label>
              <select class="form-control custom-select" id="order-sort">
                <option>All</option>
                <option>Delivered</option>
                <option>In Progress</option>
                <option>Delayed</option>
                <option>Canceled</option>
              </select>
            </div>
          </div>-->
          <div v-if="!working" class="table-responsive font-size-sm">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th>Factuur #</th>
                  <th>Datum</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Factuurbedrag</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id">
                  <td class="align-middle">
                    <a
                      class="nav-link-inline"
                      v-if="email"
                      :href="`${invoice.invoice.url}`"
                      >{{ invoice.invoice.id }}</a
                    >
                    <a
                      v-else
                      class="nav-link-inline"
                      :href="`/invoices/${invoice.invoice.id}`"
                    >
                      {{ invoice.invoice.id }}</a
                    >
                  </td>
                  <td class="align-middle">{{ invoice.invoice.date }}</td>
                  <td class="align-middle">
                    {{ invoice.invoice.project.type }}
                  </td>
                  <td class="align-middle">
                    <span
                      v-if="invoice.invoice.paid"
                      class="badge badge-success m-0"
                      >Betaald</span
                    >
                    <span v-else class="badge badge-warning m-0"
                      >Niet betaald</span
                    >
                  </td>
                  <td class="align-middle">
                    &euro; {{ invoice.invoice.original_amount | toFloat | toAmount }}
                  </td>
                  <td class="align-middle text-right" style="width: 5%">
                    <a
                      v-if="
                        !invoice.invoice.paid &&
                        invoice.invoice.psp &&
                        invoice.transaction
                      "
                      :href="invoice.transaction.paylink"
                      class="btn btn-sm btn-outline-primary"
                    >
                      Betaal nu
                    </a>
                  </td>
                  <td class="align-middle text-right" style="width: 5%">
                    <a v-if="email" :href="`${invoice.invoice.url}`">
                      <i class="mr-2" data-feather="download"></i>
                    </a>
                    <a v-else :href="`/invoices/${invoice.invoice.id}`" >
                      <i class="" data-feather="download"></i>
                    </a>
                  </td>
                  <td class="align-middle text-right" style="width: 5%">
                    <a v-if="email" :href="`/start-payment/${invoice.handle}/`" >
                      <i class="mr-2" data-feather="smartphone"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-else
            class="d-flex justify-content-center"
            style="padding-top: 50px"
          >
            <div
              class="spinner-border"
              style="width: 3rem; height: 3rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <!-- Profile info-->
        <div v-else>
          <h5 class="mb-4 pt-sm-3">Profile info</h5>
          <form class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-fn">First Name</label>
                <input
                  class="form-control"
                  type="text"
                  id="account-fn"
                  value="Daniel"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-ln">Last Name</label>
                <input
                  class="form-control"
                  type="text"
                  id="account-ln"
                  value="Adams"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-email">Email Address</label>
                <input
                  class="form-control"
                  type="email"
                  id="account-email"
                  value="daniel.adams@example.com"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-phone">Phone Number</label>
                <input
                  class="form-control"
                  type="text"
                  id="account-phone"
                  value="+7 (805) 348 95 72"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-pass">New Password</label>
                <input class="form-control" type="password" id="account-pass" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="account-confirm-pass">Confirm Password</label>
                <input
                  class="form-control"
                  type="password"
                  id="account-confirm-pass"
                />
              </div>
            </div>
            <div class="col-12">
              <hr class="mt-2 mb-3" />
              <div
                class="d-flex flex-wrap justify-content-between align-items-center"
              >
                <div class="custom-control custom-checkbox d-block">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    id="subscribe_me"
                    checked
                  />
                  <label class="custom-control-label" for="subscribe_me"
                    >Subscribe me to Newsletter</label
                  >
                </div>
                <button
                  class="btn btn-primary mt-3 mt-sm-0"
                  type="button"
                  data-toggle="toast"
                  data-target="#profile-toast"
                >
                  Update profile
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- Technical support + Tickets (visible Mobile)-->
        <!--
      <div class="d-lg-none bg-secondary px-3 py-4 mt-5">
        <h6 class="font-size-sm mb-3 pb-2 border-bottom">Technical support</h6>
        <ul class="list-unstyled">
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="mail"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            ><a class="nav-link-inline" href="mailto:support@example.com"
              >support@example.com</a
            >
          </li>
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="phone"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            ><a class="nav-link-inline" href="tel:+100331697720"
              >+1 00 33 169 7720</a
            >
          </li>
          <li class="font-size-sm mb-2">
            <i
              class="text-muted mr-2"
              data-feather="clock"
              style="width: 0.875rem; height: 0.875rem"
            ></i
            >1-2 business days
          </li>
        </ul>
        <div class="pt-2">
          <a
            class="btn btn-outline-secondary btn-sm btn-block"
            href="account-tickets.html"
            ><i class="mr-1" data-feather="tag"></i>My tickets (1)</a
          ><a
            class="btn btn-success btn-sm btn-block"
            href="account-tickets.html"
            data-toggle="modal"
            data-target="#open-ticket"
            >Submit new ticket</a
          >
        </div>
      </div>
      --></div>
    </div>
  </div>
</template>

<script>
import { userStore } from "@/stores/user";
import axios from "axios";
import feather from "feather-icons";

export default {
  name: "AccountView",
  components: {},
  data: function () {
    return {
      show: "orders",
      invoices: [],
      email: "",
      working: true,
    };
  },
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
    toAmount: function (value) {
      return parseFloat(value.toFixed(2)).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
      });
    },
  },
  mounted() {
    this.email = this.$route.query.email;
    if (this.email) {
      axios.get(`/api/invoices/?email=${this.email}`).then((response) => {
        this.invoices = response.data;
        this.working = false;
        setTimeout(() => {
          this.$nextTick(function () {
            feather.replace();
          });
        }, 500);
      });
    } else {
      axios.get("/api/myinvoices/").then((response) => {
        this.invoices = response.data;
        this.working = false;
        setTimeout(() => {
          this.$nextTick(function () {
            feather.replace();
          });
        }, 500);
      });
    }
  },
  setup() {
    const user_store = userStore();
    return {
      user_store,
    };
  },
};
</script>
