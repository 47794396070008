<template>
  <ul v-if="categories" v-bind:class="{ collapse: collapse }">
    <li
      v-bind:class="{
        'd-none': should_hide(subcat.id),
      }"
      v-for="subcat in categories"
      :key="subcat.id"
    >
      <router-link
        :class="is_active(subcat.data.canonical_name)"
        :to="tlpp() + path(subcat)"
        style="color: #222222"
      >
        {{ subcat.data.name }}
      </router-link>
      <ArticleCategory
        v-if="subcat.children"
        :valid_cats="valid_cats"
        :selected_path="selected_path"
        :categories="subcat.children"
        :parentpath="parentpath + '/' + subcat.data.canonical_name"
        :type="type"
        :sale="sale"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "ArticleCategory",
  props: [
    "type",
    "selected_path",
    "categories",
    "parentpath",
    "collapse",
    "valid_cats",
    "sale",
  ],
  methods: {
    tlpp: function(){
      var tl = null
      if(this.type == "RENTAL"){
        tl = "/verhuur/"
      } else
      if (this.type == "SALE") {
        tl = "/verkoop/"
      } else
      if(this.type == "OCCASION"){
        tl = "/occasions/"
      }
      if (this.sale) {
        return tl + "actie/" + this.sale + "/";
      }
      return tl;
    },
    is_active: function (cat) {
      console.log(this.selected_path)
      if(!this.selected_path){
        return ''
      }
      if (this.selected_path.split("/").includes(cat)) {
        return "text-warning"
      }
    },
    path: function (cat) {
      return this.parentpath + "/" + cat.data.canonical_name;
    },
    should_hide: function (cat) {
      if (this.valid_cats) {
        return !this.valid_cats.includes(cat.toString());
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
