<template>
  <div class="row">
    <a id="register"></a>
    <div class="col-md-8" v-bind:class="{ 'd-none': is_send }">
      <form id="regform" novalidate @submit.prevent>
        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f1">Open dag *</label>
            <select
              class="form-control custom-select"
              v-bind:class="{ 'is-invalid': errors.open_door_day }"
              v-model="form.open_door_day"
              required
            >
              <option v-for="odd in odds" :key="odd.id" :value="odd.id">
                <span v-if="odd.subject">{{ odd.subject }}, </span>
                <span>{{ moment(odd.date).format("dddd DD MMMM") }}</span>
                <span> {{ odd.time }}</span>
                <span v-if="odd.location">, {{ odd.location }}</span>
              </option>
            </select>
            <div class="invalid-feedback" v-if="errors.open_door_day">
              {{ errors.open_door_day[0] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f1">Naam *</label>
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': errors.name }"
              type="text"
              v-model="form.name"
            />
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f3">Email *</label>
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': errors.email }"
              type="email"
              id="f3"
              v-model="form.email"
            />
            <div class="invalid-feedback" v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f3">Telefoon</label>
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': errors.phone }"
              type="phone"
              v-model="form.phone"
            />
            <div class="invalid-feedback" v-if="errors.phone">
              {{ errors.phone[0] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f3">Aantal personen *</label>
            <input
              class="form-control"
              v-bind:class="{ 'is-invalid': errors.persons }"
              type="integer"
              v-model="form.persons"
            />
            <div class="invalid-feedback" v-if="errors.persons">
              {{ errors.persons[0] }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <label for="f10">Vragen en opmerkingen</label>
            <textarea
              class="form-control"
              v-bind:class="{ 'is-invalid': errors.remarks }"
              v-model="form.remarks"
              id="f10"
              rows="5"
            ></textarea>
            <div class="invalid-feedback" v-if="errors.remarks">
              {{ errors.remarks[0] }}
            </div>
          </div>
        </div>

        <button
          class="btn btn-primary"
          type="submit"
          :disabled="working"
          v-on:click="sendForm"
          v-bind:class="{ disabled: working }"
        >
          Nu inschrijven!
        </button>
      </form>
    </div>
    <div class="col-md-8" v-bind:class="{ 'd-none': !is_send }">
      <h3>Bedankt! Je inschrijving is ontvangen.</h3>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "OpenDoorDayRegistrationWidget",
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
  },
  methods: {
    sendForm() {
      this.working = true;
      console.log(this.form);
      var headers = {
        "X-CSRFToken": Vue.$cookies.get("csrftoken"),
      };
      if (!this.is_send) {
        axios
          .post("/api/opendoordayregistration/", this.form, { headers })
          .then(
            () => {
              this.is_send = true;
              this.working = false;
              window.scrollTo(0,0);
              window.location = "/infodag-inschrijving-bedankt";
            },
            (error) => {
              if (error.response) {
                this.errors = error.response.data;
                this.working = false;
              }
            }
          );
      }
    },
    fetchData() {
      axios.get("/api/opendoordays/?no_page=true").then((response) => {
        this.odds = response.data;
        if (this.odds) {
          this.form.open_door_day = this.odds[0].id;
        }
      });
    },
  },
  data: function () {
    return {
      is_send: false,
      working: false,
      form: {},
      errors: {},
      odds: [],
    };
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
#register {
  display: block;
  position: relative;
  top: -300px;
  visibility: hidden;
}
</style>
