<template>
  <div>
    <section class="container-fluid border-bottom px-0">
      <div class="row no-gutters">
        <div class="col-lg-6 align-self-center px-4 pt-5 pb-4 pb-lg-5">
          <div
            class="mx-auto text-center text-lg-left"
            style="max-width: 600px"
          >
            <h1>Hoe kunnen wij helpen?</h1>
            <h3 class="h6 font-weight-semibold pb-4">
              Op zoek naar een editor, cameraman, colorist, technicus of andere
              ondersteuning voor je prodcutie?
            </h3>
            <h3 class="h6 font-weight-semibold pb-4">
              Of wil je dvds, blu-rays of tapes digitaliseren? Ook leveren wij
              commercials op voor de verschillende zenders/omroepen en maken wij
              DCPs van jouw film voor vertoning in de bioscoop.
            </h3>
          </div>
        </div>
        <div class="col-lg-6">
          <img src="/static/main/images/productie.jpg" alt="Help center" />
        </div>
      </div>
    </section>
    <!-- Topics-->
    <section class="container pt-5 mt-3">
      <h3 class="text-center">Productie : AV Personeel</h3>
      <div class="row pt-4">
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/cameraman/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon"><i data-feather="video"></i></div>
                <h3 class="icon-box-title">Cameraman</h3>
                <p class="icon-box-text pb-3">
                  Op zoek naar een cameraman die je productie kan filmen.
                  Wij vinden de cameraman voor jouw project.
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  Meer info
                </div>
              </div>
            </div></a
          >
        </div>
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/editor/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon"><i data-feather="monitor"></i></div>
                <h3 class="icon-box-title">Editor</h3>
                <p class="icon-box-text pb-3">
                  Wil je iets laten monteren? Beschrijf ons de wensen en
                  eventueel het te gewenste edit programma.
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  Meer info
                </div>
              </div>
            </div></a
          >
        </div>
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/color-grader/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon"><i data-feather="sliders"></i></div>
                <h3 class="icon-box-title">Color Grading</h3>
                <p class="icon-box-text pb-3">
                  Heeft jouw film nog kleurnabewerking nodig? Ga met 1 van onze
                  coloristen aan de slag.
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  Meer info
                </div>
              </div>
            </div></a
          >
        </div>
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/geluidsman/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon">
                  <i data-feather="headphones"></i>
                </div>
                <h3 class="icon-box-title">Geluidsman</h3>
                <p class="icon-box-text pb-3">
                  Iemand nodig die zich over de microfoons en de audio opname
                  ontfermd?
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  Meer info
                </div>
              </div>
            </div></a
          >
        </div>
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/lichtman/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon"><i data-feather="user"></i></div>
                <h3 class="icon-box-title">Lichtman</h3>
                <p class="icon-box-text pb-3">
                  Opzoek naar iemand die iets voor je kan uitlichten? Boek een
                  lichtman.
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  Meer info
                </div>
              </div>
            </div></a
          >
        </div>
        <div class="col-lg-4 col-sm-6 mb-grid-gutter">
          <a class="card" href="/verhuur/artikel/autocue-operator/">
            <div class="card-body">
              <div class="icon-box text-center">
                <div class="icon-box-icon"><i data-feather="monitor"></i></div>
                <h3 class="icon-box-title">Autocue operator</h3>
                <p class="icon-box-text pb-3">
                  De operator zorgt voor het opbouwen en bedienen van de
                  prompter.
                </p>
                <div class="btn btn-pill btn-outline-primary btn-sm">
                  meer info
                </div>
              </div>
            </div></a
          >
        </div>
      </div>
    </section>
    <!-- F.A.Q-->
    <section class="container pt-4 pb-5 mt-3" id="duplicatie">
      <h3 class="text-center pb-2">Duplicatie &amp; Conversie</h3>
      <div class="row pt-4">
        <div class="col-sm-6">
          <ul class="list-unstyled">
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >DVD duplicatie en Creatie van nieuwe DVD's</a
              >
            </li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >Blu-Ray maken of dupliceren</a
              >
            </li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >Commercial MXF with or without audio adjustment</a
              >
            </li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >Filmspoelen 35mm, 16mm, 8mm</a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <ul class="list-unstyled">
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >Audiocassettes OpenReel Audio DAT</a
              >
            </li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3">
              <i
                class="text-muted mr-2"
                data-feather="file-text"
                style="width: 1.2rem; height: 1.2rem"
              ></i
              ><a class="nav-link-inline" href="#"
                >Tapes: HDCAM, DV, DVcam, HDV, VHS, VHS-c, Hi8, Video 8, Digital
                8, Digi Beta, Betacam SP, Betamax SX, Umatic, Umatic SP</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row pt-2">
        <h2>Kosten kopie / digitalisatie tape en film</h2>
        <div class="card-body" ng-editable="copy-pricing">
          <!--{{ blocks.get("copy-pricing", "") }}-->
        </div>
      </div>
    </section>
    <!-- Submit request-->
    <section class="container text-center pt-2 pb-5 mb-2">
      <h3 class="pb-3">Staat je vraag hier niet tussen?</h3>
      <div class="mb-2">
        <div class="icon-box-icon"><i data-feather="life-buoy"></i></div>
      </div>
      <a class="btn btn-primary" href="/contact/">Contact</a>
      <p class="pt-4">Neem contact met ons op en beschrijf je wensen.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "DuplicationView",
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  created() {},
};
</script>
