import { render, staticRenderFns } from "./InfodagWidget.vue?vue&type=template&id=7e114d5a&scoped=true&"
import script from "./InfodagWidget.vue?vue&type=script&lang=js&"
export * from "./InfodagWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e114d5a",
  null
  
)

export default component.exports