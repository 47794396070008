<template>
  <div v-if="page_store.page">
    <div class="container pb-2 mb-1 pt-5 text-center">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h1 class="header-red">{{ page_store.page.title }}</h1>
          <div class="d-flex justify-content-between mt-3 mb-3"></div>
          <EditableBlock
            v-if="page_store.blocks"
            :blocks="page_store.blocks"
            :page_id="page_store.page.id"
            block_name="block-1"
            type="blocks"
          />
        </div>
      </div>
    </div>
    <section class="container-fluid mt-3 px-0 split-page">
      <div class="bg-position-center">
        <div class="row pt-lg-4 no-gutters">
          <div class="bg-faded-info col-lg-1"></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pr-5 pl-5 pl-lg-0">
            <div class="px-6 px-lg-0 py-5">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-2"
                type="blocks"
              />
            </div>
          </div>
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/images/1.jpeg);
              background-size: cover;
              background-repeat: no-repeat;
            "
          ></div>
        </div>
      </div>
    </section>
    <section class="container-fluid px-0 split-page">
      <div class="bg-position-center" id="inloopdagen-widget-contianer">
        <div class="row no-gutters">
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/images/2.jpg);
              background-size: cover;
              background-repeat: no-repeat;
            "
          ></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pl-5 pr-5 pr-lg-0">
            <div class="px-6 px-lg-0 py-5 my-3">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-3"
                type="blocks"
              />
            </div>
          </div>
          <div class="bg-faded-info col-lg-1"></div>
        </div>
      </div>
    </section>



    <section class="container-fluid px-0 split-page">
      <div class="bg-position-center">
        <div class="row no-gutters">
          <div class="bg-faded-info col-lg-1"></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pr-5 pl-5 pl-lg-0">
            <div class="px-6 px-lg-0 py-5">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-4"
                type="blocks"
              />
            </div>
          </div>
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/images/3.jpg);
              background-size: cover;
              background-repeat: no-repeat;
            "
          ></div>
        </div>
      </div>
    </section>
    <section class="container-fluid px-0 split-page">
      <div class="bg-position-center" id="inloopdagen-widget-contianer">
        <div class="row no-gutters">
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/images/4.jpg);
              background-size: cover;
              background-repeat: no-repeat;
            "
          ></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pl-5 pr-5 pr-lg-0">
            <div class="px-6 px-lg-0 py-5 my-3">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-5"
                type="blocks"
              />
            </div>
          </div>
          <div class="bg-faded-info col-lg-1"></div>
        </div>
      </div>
    </section>
    <div
      class="container-fluid px-0"
      id="map"
      data-offset-top="30"
      data-ng-controller="ContactController as contactCtrl"
    >
      <ContactWidget />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue"
import { pageStore } from "@/stores/page";
import EditableBlock from "@/components/EditableBlock.vue";
import axios from "axios";

export default {
  name: "CampaignView",
  components: {
    EditableBlock,
  },
  data: function () {
    return {
      test: "test1",
      form: {},
      errors: {},
    };
  },
  methods: {
    sendContactForm() {
      let payload = { ...this.form, subject: "Contactformulier" };
      if (!this.is_send) {
        axios.post("/api/contact/", payload).then(
          () => {
            this.is_send = true;
            this.errors = {};
          },
          (error) => {
            if (error.response) {
              this.errors = error.response.data;
            }
          }
        );
      }
    },
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>

<style>
section.split-page h2 {
  font-weight: 300 !important;
  margin-bottom: 20px;
}
</style>
