<template>
  <form class="pt-4" novalidate @submit.prevent>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i data-feather="mail"></i></span>
      </div>
      <input
        class="form-control"
        type="email"
        name="login-email"
        v-bind:class="{ 'is-invalid': errors.email }"
        placeholder="Email"
        v-model="email"
        required
      />
      <div v-if="errors.email" class="invalid-feedback">
        {{ errors.email[0] }}
      </div>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i data-feather="lock"></i></span>
      </div>
      <input
        class="form-control"
        type="password"
        name="login-password"
        v-bind:class="{ 'is-invalid': errors.password }"
        placeholder="Password"
        v-model="password"
        required
      />
      <div v-if="errors.password" class="invalid-feedback">
        {{ errors.password[0] }}
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-between">
      <div class="custom-control custom-checkbox"></div>
      <a class="nav-link-inline font-size-sm" href="/wachtwoord-aanvraag/"
        >Wachtwoord vergeten?</a
      >
    </div>
    <hr class="mt-4" />
    <div class="form-group" v-if="errors.message">
      {{ errors.message }}
    </div>
    <div class="text-right mt-3">
      <button
        v-on:click="doLogin"
        v-bind:class="{ disabled: !enabled }"
        class="btn btn-primary"
        type="submit"
      >
        Sign In
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { userStore } from "@/stores/user";

export default {
  name: "LoginWidget",
  components: {},
  props: ["redirect"],
  data: function () {
    return {
      enabled: true,
      errors: {},
      email: "",
      password: "",
    };
  },
  computed: {
    user() {
      return this.store.user;
    },
  },
  watch: {
    user: function () {
      if (this.store.user) {
        this.enabled = false;
        this.errors.message = "Je bent al ingelogd";
      }
    },
  },
  methods: {
    checkLogin: function () {
      let r = ""
      if (this.$route.query.r) {
        r = this.$route.query.r;
      }
      if (this.redirect) {
        r = this.redirect
      }
      var payload = {
        email: this.email,
        redirect: r,
      };
      return axios.post("/api/auth/check-email/", payload).then((result) => {
        if (result.data.status == "101") {
          window.location.href = `/activate-account/?r=${r}`;
        }
        return result.data.status
      });
    },
    doLogin: function () {
      this.errors = {};
      var payload = {
        email: this.email,
        password: this.password,
      };
      this.checkLogin().then(() => {
        axios.post("/api/auth/login/", payload).then(
          () => {
            console.log(this.redirect);
            if (this.$route.query.r) {
              window.location.href = `/${this.$route.query.r}`;
            }
            if (this.redirect) {
              window.location.href = `/${this.redirect}`;
              return
            }
            window.location.href = "/";
          },
          (error) => {
            if (error.response) {
              this.errors = error.response.data;
            }
          }
        );
      });
    },
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
};
</script>

<style scoped></style>
