<template>
  <div
    data-editable
    :data-type="type"
    :data-name="block_name"
    :data-pageid="page_id"
    :data-block="getBlock(block_name).id"
    v-html="getBlock(block_name).content"
  ></div>
</template>

<script>
export default {
  name: "EditableBlock",
  props: ["blocks", "page_id", "block_name", "type"],
  methods: {
    getBlock(name) {
      let block = this.blocks.get(name);
      console.log("getBlock", name, block);
      if (block) {
        return block;
      }
      return new Map([
        ["id", null],
        ["content", ""],
      ]);
    },
  },
};
</script>
