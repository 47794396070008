<template>
  <div class="container-fluid px-0 pt-4 pt-lg-5" id="map" data-offset-top="30">
    <div class="row no-gutters">
      <div class="col-lg-6 iframe-full-height-wrap">
        <iframe
          class="iframe-full-height"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4876.102342986735!2d4.915668876617351!3d52.33321777201281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60bcd8171e147%3A0x47b96c248c475155!2sOpen%20Studio!5e0!3m2!1sen!2snl!4v1711106086608!5m2!1sen!2snl"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div class="col-lg-6 px-4 px-xl-5 py-5 border-top">
        <div v-if="!is_send">
          <h2 class="h4 mb-4">Plan een adviesgesprek in</h2>
          <p></p>
          <form class="needs-validation mb-3" novalidate>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="cf-name"
                    >Je naam:&nbsp;<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    id="cf-name"
                    v-model="form.name"
                    required
                  />
                  <div class="invalid-feedback">Please fill in you name!</div>
                </div>
              </div>
            </div>
            <!--end row-->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="cf-email"
                    >Emailadres:&nbsp;<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="email"
                    id="cf-email"
                    v-model="form.email"
                    required
                  />
                  <div class="invalid-feedback">
                    Please provide valid email address!
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="cf-phone"
                    >Telefoonnummer:&nbsp;<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    id="cf-phone"
                    placeholder="06 12 34 56 78"
                    v-model="form.phone"
                    required
                  />
                  <div class="invalid-feedback">
                    Please provide valid phone number!
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cf-message"
                >Stel hier je vraag:&nbsp;<span class="text-danger"
                  >*</span
                ></label
              >
              <textarea
                class="form-control"
                id="cf-message"
                rows="6"
                placeholder="Je ontvangt binnen een werkdag bericht"
                v-model="form.message"
                required
              ></textarea>
              <div class="invalid-feedback">Please write a message!</div>
            </div>
            <a
              class="btn btn-outline-success btn-block"
              v-if="!is_send"
              v-on:click="sendContactForm"
              v-bind:class="{ disabled: is_send }"
            >
              Verstuur
            </a>
          </form>
        </div>
        <div class="pt-4" v-if="is_send" data-ng-cloak>
          <h3>Bericht is verzonden. Wij nemen zo snel mogelijk contact op.</h3>
        </div>
      </div>
    </div>
    <div class="text-center pt-5 mt-2">
      <h4 class="pb-3">Niet gevonden wat je zocht? Laat ons je helpen.</h4>
      <div class="mb-2">
        <div class="icon-box-icon"><i data-feather="life-buoy"></i></div>
      </div>
      <button type="button" class="btn btn-outline-primary" href="/contact/">
        Mail ons
      </button>
      <button
        type="button"
        class="btn btn-outline-success"
        href="tel:+31205246060"
      >
        020 524 6060
      </button>
      <p class="pt-4">
        We zijn <mark>maandag tot en met vrijdag</mark> tussen
        <mark>09:00 en 17:00</mark>
        <a href="tel:+31205246060">telefonisch</a> bereikbaar.
      </p>
      <p>Op e-mails zullen we zo snel mogelijk reageren.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactWidget",
  props: ["subject", "to"],
  data: function () {
    return {
      is_send: false,
      form: {},
    };
  },
  methods: {
    sendContactForm() {
      let payload = {
        ...this.form,
        subject: this.subject,
        to: this.to,
      };
      if (!this.is_send) {
        axios.post("/api/contact/", payload).then(
          () => {
            this.is_send = true;
            this.errors = {};
            this.form = {};
          },
          (error) => {
            if (error.response) {
              this.errors = error.response.data;
            }
          }
        );
      }
    },
  },
  created() {
  },
};
</script>

<style scoped></style>
