<template>
  <div  id="login-view" class="container pb-5 mb-sm-4">
    <div class="row pt-5">
      <div
        class="col-md-6 pt-sm-3"
      >
        <div class="card">
          <div id="login-widget-container" class="card-body">
            <LoginWidget />
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-5 pt-sm-3">
        <h2 class="h4 mb-3">Geen account? Sign up</h2>
        <RegistrationWidget colsize="col-sm-6"/>
        <!--{% include 'widgets/register.j2' %} -->
      </div>
    </div>
  </div>
</template>

<script>
import LoginWidget from "@/components/LoginWidget.vue";
import RegistrationWidget from "@/components/RegistrationWidget.vue";

export default {
  name: "LoginView",
  components: {
    LoginWidget,
    RegistrationWidget,
  },
};
</script>
