import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeViewNew from "../views/HomeViewNew.vue";
import EduView from "../views/EduView.vue";
import TussenjaarSectionView from "../views/TussenjaarSectionView.vue";
// import CourseView from "../views/CourseView.vue";
import CourseView2 from "../views/CourseView2.vue";
import ContactView from "../views/ContactView.vue";
import DuplicationView from "../views/DuplicationView.vue";
import ArticleListView from "../views/ArticleListView2.vue";
//import ArticleView from "../views/ArticleView.vue";
import LoginView from "../views/LoginView.vue";
import OpenDoorDayView from "../views/OpenDoorDayView.vue";
import CourseRegisterView from "../views/CourseRegisterView.vue";
// import CheckoutView from "../views/CheckoutView.vue";
// import CheckoutRecipientView from "../views/CheckoutRecipientView.vue";
// import CheckoutConfirmView from "../views/CheckoutConfirm.vue";
import CalendarView from "../views/CalendarView.vue";
import PageView from "../views/PageView.vue";
//import KantoorHurenView from "../views/KantoorHurenView.vue";
import ActivateAccountView from "../views/ActiveAccountView.vue";
import AccountView from "../views/AccountView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import CampaignView from "../views/CampaignView.vue";

Vue.use(VueRouter);

const article_list_route_func = function (type) {
  return function (route) {
    let page = Number.parseInt(route.query.page);
    if (Number.isNaN(page)) {
      page = 1;
    }
    let catpath = route.params.catpath || "";
    let saleSlug = route.params.saleSlug;
    return {
      page: page,
      catpath: catpath.replace(/\/+$/, ''),
      q: route.query.q,
      type: type,
      sale: saleSlug,
    };
  };
};

const routes = [
  {
    path: "/_",
    redirect: "/_/home",
  },
  {
    path: "/",
    name: "index",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    alias: "/_/home",
  },
  {
    path: "/homenew",
    name: "homenew",
    component: HomeViewNew,
    alias: "/_/homenew",
  },
  {
    path: "/account",
    name: "acount",
    component: AccountView,
  },
  {
    path: "/activate-account",
    name: "activate",
    component: ActivateAccountView,
  },
  {
    path: "/reset-password",
    name: "password",
    component: ResetPasswordView,
    alias: ["/wachtwoord-aanvraag"],
  },
  {
    path: "/educatie/c/:slug",
    name: "Course",
    component: CourseView2,
    alias: ["/_/educatie/c/:slug", "/opleidingen/:slug"],
  },
  {
    path: "/educatie/c/new/:slug",
    name: "Course2",
    component: CourseView2,
    alias: ["/_/educatie/c/new/:slug", "/opleidingen/new/:slug"],
  },
  {
    path: "/educatie/c/:slug/:run/inschrijven",
    name: "CourseRegister",
    component: CourseRegisterView,
    alias: "/opleidingen/:slug/:run/inschrijven",
  },
  {
    path: "/educatie/tussenjaar/:cat?",
    name: "Tussenjaar",
    component: TussenjaarSectionView,
    alias: ["/opleidingen/tussenjaar/:cat?", "/_/educatie/tussenjaar/:cat?"],
  },
  {
    path: "/educatie/:section/:cat?",
    name: "Educatie",
    component: EduView,
    alias: ["/opleidingen/:section/:cat?", "/_/educatie/:section/:cat?"],
  },
  {
    path: "/verhuur/artikel/:slug",
    name: "Verhuurartikel",
    //component: ArticleView,
    component: () => import("../views/ArticleView.vue"),
    props: { type: "RENTAL" },
    alias: ["/_/verhuur/artikel/:slug"],
  },
  {
    path: "/verkoop/artikel/:slug",
    name: "Verkoopartikel",
    //component: ArticleView,
    component: () => import("../views/ArticleView.vue"),
    props: { type: "SALE" },
    alias: ["/_/verkoop/artikel/:slug"],
  },
  {
    path: "/occasions/artikel/:slug",
    name: "Occasionartikel",
    //component: ArticleView,
    component: () => import("../views/ArticleView.vue"),
    props: { type: "OCCASION" },
    alias: ["/_/occasions/artikel/:slug"],
  },
  {
    path: "/verhuur/:catpath(.*)?",
    name: "Verhuur",
    component: ArticleListView,
    props: article_list_route_func("RENTAL"),
  },
  {
    path: "/verkoop/actie/:saleSlug/:catpath(.*)?",
    name: "VerkoopActie",
    component: ArticleListView,
    props: article_list_route_func("SALE"),
  },
  {
    path: "/verkoop/:catpath(.*)?",
    name: "Verkoop",
    component: ArticleListView,
    props: article_list_route_func("SALE"),
  },
  {
    path: "/verhuur",
    redirect: { name: "Verhuur" },
  },
  {
    path: "/verkoop",
    redirect: { name: "Verkoop" },
  },
  {
    path: "/occasions/actie/:saleSlug/:catpath(.*)?",
    name: "OccasionActie",
    component: ArticleListView,
    props: article_list_route_func("OCCASION"),
  },
  {
    path: "/occasions/:catpath(.*)?",
    name: "Occasion",
    component: ArticleListView,
    props: article_list_route_func("OCCASION"),
  },
  {
    path: "/educatie",
    redirect: { name: "Educatie", params: { section: "opleidingen" } },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
    alias: "/_/contact",
  },
  {
    path: "/duplicatie",
    name: "Duplication",
    component: DuplicationView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/inloopdagen",
    redirect: { name: "OpenDoorDay" },
  },
  {
    path: "/infodag",
    name: "OpenDoorDay",
    component: OpenDoorDayView,
    alias: "/_/infodag",
  },
  {
    path: "/offerte",
    name: "Offerte",
    //component: CheckoutView,
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/order-details-1",
    name: "Order1",
    //component: CheckoutRecipientView,
    component: () => import("../views/CheckoutRecipientView.vue"),
  },
  {
    path: "/order-details-2",
    name: "Order2",
    //component: CheckoutConfirmView,
    component: () => import ("../views/CheckoutConfirm.vue"),
  },
  {
    path: "/verify-payment/:handle",
    name: "VerifyPayment",
    component: () => import ("../views/VerifyPayment.vue"),
  },
  {
    path: "/verify-invoice-payment/:handle",
    name: "VerifyInvoicePayment",
    component: () => import ("../views/VerifyInvoicePayment.vue"),
  },
  {
    path: "/verify-project-payment/:handle",
    name: "VerifyProjectPayment",
    component: () => import ("../views/VerifyProjectPayment.vue"),
  },
  {
    path: "/agenda/:cat?",
    name: "Agenda",
    component: CalendarView,
  },
  // {
  //   path: "/kantoor-ruimte-huren-amsterdam",
  //   name: "KantoorHuren",
  //   component: KantoorHurenView,
  //   alias: "/_/kantoor-ruimte-huren-amsterdam",
  // },
  {
    path: "/cmp/:pathMatch(.*)*",
    name: "Campaign",
    component: CampaignView,
    alias: "/_/cmp/:pathMatch(.*)*",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page",
    component: PageView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  // scrollBehavior(to, from, savedPosition) {
  //   console.log(savedPosition)
  //   if (to.name == from.name) {
  //     return null;
  //   }
  //   if (savedPosition) {
  //     return savedPosition;
  //   }
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({ x: 0, y: 0, behavior: "smooth" });
  //     }, 100);
  //   });
  // },
});

export default router;
