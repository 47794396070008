<template>
  <div id="reset-password-view" class="container">
    <h5>Wachtwoord opnieuw instellen</h5>
    <form v-if="!submitted" class="pt-4" novalidate @submit.prevent>
      <p>Geef je emailadres op om een password reset link te ontvangen</p>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i data-feather="lock"></i></span>
        </div>
        <input
          class="form-control"
          type="text"
          v-bind:class="{ 'is-invalid': errors.email }"
          placeholder="Email"
          name="set-password"
          v-model="email"
          required
        />
        <div v-if="errors.email" class="invalid-feedback">
          {{ errors.email[0] }}
        </div>
      </div>
      <div class="form-group" v-if="errors.message">
        {{ errors.message }}
      </div>
      <div class="text-right mt-3">
        <button
          v-on:click="doResetPassword"
          v-bind:class="{ disabled: !enabled }"
          class="btn btn-primary"
          type="submit"
        >
          Verstuur
        </button>
      </div>
    </form>
    <div v-else>
      Er is een email verstuurd met instructies voor het opnieuw instellen van je wachtwoord.
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPasswordView",
  components: {
  },
  data: function () {
    return {
      email: "",
      errors: {},
      submitted: false,
      enabled:true,
    };
  },
  methods: {
    doResetPassword: function () {
      this.errors = {};
      var payload = {
        email: this.email,
      };
      axios.post("/api/auth/reset-password/", payload).then(
        () => {
          this.submitted = true;
        },
        (error) => {
          if (error.response) {
            this.errors = error.response.data;
          }
        }
      );
    },
  },
};
</script>


<style>
#reset-password-view{
  max-width: 500px;
  padding-top:80px;
  padding-bottom:200px;
}
</style>
