<template>
  <div
    class="offcanvas offcanvas-reverse"
    id="offcanvas-search"
  >
    <div
      class="offcanvas-header d-flex justify-content-between align-items-center"
    >
      <h3 class="offcanvas-title">Doorzoek de site</h3>
      <button
        class="close"
        type="button"
        data-dismiss="offcanvas"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="offcanvas-body-inner">
        <div class="input-group pt-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-icon"
              ><i data-feather="search"></i
            ></span>
          </div>
          <input
            class="form-control"
            type="text"
            id=""
            placeholder="Zoektermen"
            aria-label="Zoektermen"
            aria-describedby="search-icon"
            v-model="filterText"
          />
        </div>
        <small class="form-text pt-1">Zoeken binnen alle afdelingen</small>
        <div class="widget widget-featured-entries pt-3">
          <div
            style="margin-bottom: 1rem"
            v-for="item in articles"
            :key="item.id"
          >
            <div class="media" data-ng-repeat="item in items">
              <div class="featured-entry-thumb mr-3">
                <a
                  :href="tlp(item) + item.slug"
                  target="_self"
                  style="display: flex; height: 64px; align-items: center"
                >
                  <img
                    style="
                      max-width: 100%;
                      max-height: 100%;
                      margin: 0 auto;
                      display: block;
                    "
                    v-if="item.type == 'COURSE'"
                    src="/media/_versions/images/453799cfd303971b3d9e2cf34a9e18e4_thumbnail.jpg"
                    width="64"
                    alt="Product thumb"
                  />
                  <img
                    style="
                      max-width: 100%;
                      max-height: 100%;
                      margin: 0 auto;
                      display: block;
                    "
                    v-if="item.type != 'COURSE'"
                    :src="'/media/' + item.image_small"
                    width="64"
                    alt="Product thumb"
                  />
                </a>
              </div>
              <div class="media-body">
                <h6 class="featured-entry-title">
                  <a :href="tlp(item) + item.slug" target="_self">
                    <span
                      v-if="item.type == 'COURSE'"
                      class="badge badge-pill badge-info"
                      >Educatie</span
                    >
                    <span
                      v-if="item.type == 'RENTAL'"
                      class="badge badge-pill badge-success"
                      >Huren</span
                    >
                    <span
                      v-if="item.type == 'OCCASION'"
                      class="badge badge-pill badge-warning"
                      >Occasion</span
                    >
                    <span
                      v-if="item.type == 'SALE'"
                      class="badge badge-pill badge-danger"
                      >Kopen</span
                    >
                    <span
                      v-if="item.type == '...'"
                      class="badge badge-pill badge-danger"
                      >Kopen</span
                    >
                    {{ item.brand }}
                    {{ item.name }}
                  </a>
                </h6>
                <p class="featured-entry-meta">
                  &euro;{{ item.price }}
                  <span v-if="item.type == 'RENTAL'"> per dag</span>
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchOffcanvas",
  data: function () {
    return {
      articles: [],
      offset: 0,
      end: 20,
      page_size: 20,
      filterText: "",
    };
  },
  watch: {
    filterText: function () {
      this.search();
    },
  },
  methods: {
    tlp(item) {
      if (item.type == "RENTAL") {
        return "/verhuur/artikel/";
      }
      if (item.type == "SALE") {
        return "/verkoop/artikel/";
      }
      if (item.type == "OCCASION") {
        return "/occasions/artikel/";
      }
      if (item.type == "COURSE") {
        return "/educatie/c/";
      }
    },
    search() {
      var params = {
        params: {
          q: this.filterText,
          start: this.offset,
          end: this.end,
        },
      };
      axios
        .get("/api/search/", params)
        .then((response) => (this.articles = response.data[0]));
    },
  },
};
</script>

<style scoped></style>
