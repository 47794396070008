<template>
  <div v-if="page_store.page">
    <div class="container pb-2 mb-1 pt-5 text-center">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h1 class="header-red">{{ page_store.page.title }}</h1>
          <div class="d-flex justify-content-between mt-3 mb-3">
            <div>
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-usp-1"
                type="blocks"
              />
            </div>
            <div>
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-usp-2"
                type="blocks"
              />
            </div>
            <div>
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-usp-3"
                type="blocks"
              />
            </div>
          </div>
          <EditableBlock
            v-if="page_store.blocks"
            :blocks="page_store.blocks"
            :page_id="page_store.page.id"
            block_name="block-1"
            type="blocks"
          />
        </div>
      </div>
    </div>
    <section class="container-fluid mt-3 px-0 split-page">
      <div class="bg-position-center">
        <div class="row pt-lg-4 no-gutters">
          <div class="bg-faded-info col-lg-1"></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pr-5 pl-5 pl-lg-0">
            <div class="px-6 px-lg-0 py-5">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-2"
                type="blocks"
              />
            </div>
          </div>
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/homepage/productie.jpg);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center -10px;
            "
          ></div>
        </div>
      </div>
    </section>
    <section class="container-fluid px-0 split-page">
      <div class="bg-position-center" id="inloopdagen-widget-contianer">
        <div class="row no-gutters">
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/homepage/promo-banner.jpg);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center -90px;
            "
          ></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pl-5 pr-5 pr-lg-0">
            <div class="px-6 px-lg-0 py-5 my-3">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-3"
                type="blocks"
              />
            </div>
          </div>
          <div class="bg-faded-info col-lg-1"></div>
        </div>
      </div>
    </section>
    <div
      class="container-fluid px-0"
      id="map"
      data-offset-top="30"
      data-ng-controller="ContactController as contactCtrl"
    >
      <div class="row no-gutters">
        <div class="col-lg-6 iframe-full-height-wrap">
          <iframe
            class="iframe-full-height"
            width="600"
            height="250"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46356.1098451574!2d4.823680809453922!3d52.36510857848007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60bcd8171e147%3A0x47b96c248c475155!2sOpen+Studio!5e0!3m2!1snl!2snl!4v1474234626724"
          ></iframe>
        </div>
        <div class="col-lg-6 px-4 px-xl-5 py-5 border-top">
          <h2 class="h4 mb-4">Contacformulier</h2>
          <form class="mb-3" novalidate @submit.prevent>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-name"
                    >Naam:&nbsp;<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    data-ng-model="contactCtrl.form_data.name"
                    v-bind:class="{ 'is-invalid': errors.name }"
                    v-model="form.name"
                    required
                  />
                  <div v-if="errors.name" class="invalid-feedback">
                    {{ errors.name[0] }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-email"
                    >Emailadres:&nbsp;<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="email"
                    placeholder=""
                    v-bind:class="{ 'is-invalid': errors.email }"
                    v-model="form.email"
                    required
                  />
                  <div v-if="errors.email" class="invalid-feedback">
                    {{ errors.email[0] }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-phone">Telefoonnummer:</label>
                  <input
                    class="form-control"
                    type="text"
                    v-bind:class="{ 'is-invalid': errors.phone }"
                    v-model="form.phone"
                    placeholder=""
                  />
                  <div v-if="errors.phone" class="invalid-feedback">
                    {{ errors.phone[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cf-message"
                >Je bericht:&nbsp;<span class="text-danger">*</span></label
              >
              <textarea
                class="form-control"
                v-bind:class="{ 'is-invalid': errors.message }"
                rows="6"
                v-model="form.message"
                placeholder=""
                required
              ></textarea>
              <div v-if="errors.message" class="invalid-feedback">
                {{ errors.message[0] }}
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              v-on:click="sendContactForm"
              v-bind:class="{ disabled: is_send }"
            >
              Verstuur je bericht
            </button>
            <div class="pt-4" v-if="is_send" data-ng-cloak>
              <h3>
                Bericht is verzonden. Wij nemen zo snel mogelijk contact op.
              </h3>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue"
import { pageStore } from "@/stores/page";
import EditableBlock from "@/components/EditableBlock.vue";
import axios from "axios";

export default {
  name: "CampaignView",
  components: {
    EditableBlock,
  },
  data: function () {
    return {
      test: "test1",
      form: {},
      errors: {},
    };
  },
  methods: {
    sendContactForm() {
      let payload = { ...this.form, subject: "Contactformulier" };
      if (!this.is_send) {
        axios.post("/api/contact/", payload).then(
          () => {
            this.is_send = true;
            this.errors = {};
          },
          (error) => {
            if (error.response) {
              this.errors = error.response.data;
            }
          }
        );
      }
    },
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>

<style>
section.split-page h2 {
  font-weight: 300 !important;
  margin-bottom: 20px;
}
</style>
