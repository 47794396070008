<template>
  <div class="row">
    <div class="col-md-12">
      <div ng-editor="main-block"></div>
      <table class="table table-vcenter table-hover table-striped">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Tijd</th>
            <th>Locatie</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="day in days" :key="day.id">
            <td>
              <i class="fa fa-fw fa-calendar-o text-primary"></i>
              <span v-if="size == 'small'" class="small">
                {{ moment(day.date).format("DD-MM") }}
              </span>
              <span v-if="size != 'small'">
                {{ moment(day.date).format("DD-MM-YYYY") }}
              </span>
              <span v-if="size != 'small'">
                ({{ moment(day.date).format("dddd") }})
              </span>
            </td>
            <td>
              <i class="fa fa-fw fa-clock-o text-primary"></i>
              <span v-bind:class="{ small: size == 'small' }">
                {{ day.time }}
              </span>
            </td>
            <td>
              <a href="/inloopdagen/#register">
                <span v-bind:class="{ small: size == 'small' }">
                  {{day.location}}</span
                >
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OpenDoorDayWidget",
  props: ["size"],
  data: function () {
    return {
      days: [],
    };
  },
  methods: {
    fetchData() {
      var params = {
        params: {
          no_page: true,
        },
      };
      axios
        .get("/api/opendoordays/", params)
        .then((response) => (this.days = response.data));
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
