<template>
  <CampaignTemplate v-if="page_store.page.template == 'CAMPAIGN'"/>
  <PageTemplate v-else/>
</template>

<script>
import CampaignTemplate from "@/components/CampaignTemplate.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { pageStore } from "@/stores/page";

export default {
  name: "CampaignView",
  components: {
    CampaignTemplate,
    PageTemplate,
  },
  data: function () {
    return {
    };
  },
  methods: {
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>

<style>
section.split-page h2 {
  font-weight: 300 !important;
  margin-bottom: 20px;
}
</style>
