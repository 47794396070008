<template>
  <div class="offcanvas offcanvas-reverse" id="offcanvas-account" ng-cloak>
    <div class="offcanvas-header d-flex justify-content-between align-items-center">
      <h3 class="offcanvas-title">Sign in / Create account</h3>
      <button class="close" type="button" data-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="offcanvas-body">
      <div class="offcanvas-body-inner">
        <ul class="nav nav-tabs nav-justified" role="tablist">
          <li class="nav-item"><a class="nav-link active" href="#signin" data-toggle="tab" role="tab"><i data-feather="log-in"></i>&nbsp;Sign In</a></li>
          <li class="nav-item"><a class="nav-link" href="#signup" data-toggle="tab" role="tab"><i data-feather="user"></i>&nbsp;Sign Up</a></li>
        </ul>
        <div class="tab-content pt-1">
          <div class="tab-pane fade show active" data-ng-controller="LoginController as loginCtrl" id="signin" role="tabpanel"  ng-init="loginCtrl.redirect='/profiel'">
            <LoginWidget/>
          </div>
          <div class="tab-pane fade" id="signup" data-ng-controller="UserRegistrationController as regCtrl" role="tabpanel" ng-init="regCtrl.login=true;regCtrl.redirect='/bedankt/gebruikersregistratie'">
            <RegistrationWidget  redirect="/" colsize="col-12"/>
          </div>
        </div>
        <!--<div class="d-flex align-items-center pt-5">
          <hr class="w-100">
          <div class="px-3 w-100 text-nowrap font-weight-semibold">Or sign in via</div>
          <hr class="w-100">
        </div>
        <div class="text-center pt-4"><a class="social-btn sb-facebook mx-2 mb-3" href="#" data-toggle="tooltip" title="Facebook"><i class="flaticon-facebook"></i></a><a class="social-btn sb-google-plus mx-2 mb-3" href="#" data-toggle="tooltip" title="Google"><i class="flaticon-google-plus"></i></a><a class="social-btn sb-twitter mx-2 mb-3" href="#" data-toggle="tooltip" title="Twitter"><i class="flaticon-twitter"></i></a></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import LoginWidget from "@/components/LoginWidget.vue";
import RegistrationWidget from "@/components/RegistrationWidget.vue";
export default {
  name: 'AccountOffcanvas',
  components: {
    LoginWidget,
    RegistrationWidget,
  },
}
</script>

<style scoped>
</style>
