import axios from "axios";
import { defineStore } from "pinia";

export const pageStore = defineStore("page", {
  state: () => ({
    page: null,
    blocks: null,
    widgets: null,
  }),
  getters: {},
  actions: {
    async get_page(path) {
      var slug = path
        .replace(/^\/_/g, "")
        .replace(/\/+$/g, "")
        .replace(/^\/+/g, "")
        .split(/[#?]/)[0];
      slug = slug ? slug : "home";
      console.log(slug);
      if (slug) {
        await axios.get("/api/main/pages/" + slug + "/").then(
          (response) => {
            this.page = response.data;
            this.blocks = new Map(
              response.data.blocks.map(function (b) {
                return [b.name, b];
              })
            );
          },
          () => {
            this.page = null;
          }
        );
      }
    },
    async get_widgets() {
      await axios.get("/api/main/widgets/?no_page=True").then((response) => {
        this.widgets = new Map(
          response.data.map(function (b) {
            return [b.ref, b];
          })
        );
      });
    },
  },
});
