import axios from "axios";
import Vue from "vue";
import { defineStore } from "pinia";

const do_check_login = async function (csrf) {
  let user = null;
  var headers = {
    "X-CSRFToken": csrf,
  };
  await axios.post("/api/auth/checklogin/", {}, { headers }).then(
    (response) => {
      user = response.data;
    },
    () => {
      user = null;
    }
  );
  return user;
};

export const userStore = defineStore("user", {
  state: () => ({
    user: null,
  }),
  getters: {},
  actions: {
    async check_login() {
      this.user = await do_check_login(Vue.$cookies.get("csrftoken"));
    },
  },
});
