<template>
  <div class="widget widget-categories mb-4 py-1">
    <h3 class="widget-title">Filter artikelen</h3>
    <div class="flex-grow-1">
      <div class="input-group flex-nowrap">
        <div class="input-group-prepend">
          <span class="input-group-text rounded-left" id="search-icon"
            ><i data-feather="search"></i
          ></span>
        </div>
        <input
          v-model="filterText"
          class="form-control rounded-right"
          type="text"
          id="site-search"
          placeholder="Filter"
          aria-label="Filter"
          aria-describedby="search-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterWidget",
  data: function () {
    return {
      filterText: '',
    };
  },
};
</script>

<style scoped></style>
