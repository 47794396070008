<template>
  <div class="widget widget-categories mb-4 py-1">
    <h3 class="widget-title">Categorie:</h3>
    <ul id="shopCategories" ng-cloak>
      <li class="has-children">
        <router-link :to="type_tl_url" style="color: #222222">
          Alles
        </router-link>
      </li>
      <li
        class="has-children"
        v-bind:class="{ 'd-none': should_hide(category.id) }"
        v-for="category in categories"
        :key="category.id"
      >
        <router-link
          :to="type_tl_url + category.data.canonical_name"
          v-bind:class="{
            collapsed: top_level_path != category.data.canonical_name,
            'text-warning': top_level_path == category.data.canonical_name,
          }"
          style="color: #222222"
        >
          <i
            v-if="category.children && category.children.length != 0"
            class="widget-categories-indicator"
            data-feather="chevron-down"
          ></i>
          {{ category.data.name }}
        </router-link>
        <ArticleCategory
          :valid_cats="valid_cats"
          :selected_path="path"
          :collapse="top_level_path != category.data.canonical_name"
          :categories="category.children"
          :parentpath="category.data.canonical_name"
          :type="type"
          :sale="sale"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ArticleCategory from "@/components/ArticleCategory.vue";

export default {
  name: "CategoryWidget",
  props: ["categories", "path", "type", "valid_cats", "sale"],
  components: {
    ArticleCategory,
  },
  methods: {
    should_hide: function (cat) {
      if (this.valid_cats) {
        return !this.valid_cats.includes(cat.toString());
      }
      return true;
    },
  },
  data: function () {
    return {
      top_level_path: null,
      type_tl_url: null,
    };
  },
  watch: {
    path: function () {
      this.top_level_path = null;
      if (this.path) {
        this.top_level_path = this.path.split("/")[0];
      }
    },
  },
  created() {
    if (this.type == "RENTAL") {
      this.type_tl_url = "/verhuur/";
    }
    if (this.type == "SALE") {
      this.type_tl_url = "/verkoop/";
    }
    if (this.type == "OCCASION") {
      this.type_tl_url = "/occasions/";
    }
    if (this.sale) {
      this.type_tl_url = this.type_tl_url + "actie/" + this.sale + "/";
    }
    if (this.path) {
      this.top_level_path = this.path.split("/")[0];
    }
  },
};
</script>

<style scoped></style>
