<template>
  <div class="home">
    <section class="container-fluid bg-secondary px-0">
      <div class="row no-gutters align-items-center" v-if="config && config.banner_items">
        <div class="col-md-6">
          <div class="mx-auto bg-light my-sm-4" style="max-width: 37rem">
            <div
              class="owl-carousel trigger-carousel main-carousels"
              data-owl-carousel='{ "nav": true, "dots": false, "loop": true, "autoHeight": true }'
              data-target-carousel="#target-carousel"
            >
              <div
                class="py-5 px-3 px-sm-5"
                v-for="banner in config.banner_items"
                :key="banner.id"
              >
                <div v-html="banner.content"></div>
                <a
                  v-for="link in banner.links" :key="link.id"
                  class="btn btn-primary mr-2 btn-sm"
                  :href="link.link_target"
                  >{{ link.link_name }}<i class="ml-2" data-feather="arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="owl-carousel main-carousels"
            id="target-carousel"
            data-owl-carousel='{ "nav": false, "dots": false, "loop": true, "mouseDrag": false, "touchDrag": false, "pullDrag": false, "animateOut": "fadeOut" }'
          >
            <img
              class="ml-auto mr-0"
              v-for="banner in config.banner_items"
              :key="banner.id"
              :src="`/media/${banner.image}`"
              alt="Film Opleidingen Amsterdam"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Popular categories (carousel)-->
    <section class="container py-5 mt-3">
                <EditableBlock
                  v-if="page_store.blocks"
                  :blocks="page_store.blocks"
                  :page_id="page_store.page.id"
                  block_name="slide-3"
                  type="blocks"
                />
      <h2 class="h3 text-center pb-4">Verhuurassortiment</h2>
      <div
        class="owl-carousel"
        data-owl-carousel='{ "nav": false, "dots": true, "margin": 30, "responsive": {"0":{"items":1},"460":{"items":2}, "768":{"items":3}} }'
      >
        <div class="card border-1 px-3 pt-3">
          <div style="height: 230px">
            <a class="card-img-tiles" href="/verhuur/camera/">
              <div class="main-img">
                <img
                  src="/media/_versions/homepage/e3e8181d80562ede6f5a5c67d899e502_medium.jpg"
                  alt="Camera huren"
                />
              </div>
              <div class="thumblist">
                <img
                  src="/media/_versions/homepage/6eee2675cd7c14b04acad27d8dce1b05_medium.png"
                  alt="Camera huren"
                /><img
                  src="/media/_versions/homepage/91f5dc5506e83edc0d8289ff4082bf62_medium.jpg"
                  alt="Camera huren"
                />
              </div>
            </a>
          </div>
          <div class="card-body mt-n1 py-4 text-center">
            <h2 class="h5 mb-1">Camera's</h2>
            <a
              class="btn btn-pill btn-outline-primary btn-sm"
              href="/verhuur/camera/"
              >Camera huren</a
            >
          </div>
        </div>

        <div class="card border-1 px-3 pt-3">
          <div style="height: 230px">
            <a class="card-img-tiles" href="/verhuur/lenzen/">
              <div class="main-img">
                <img
                  src="/media/_versions/homepage/fa512c616563f41281ed3ba1599d791a_medium.jpg"
                  alt="Lens huren"
                />
              </div>
              <div class="thumblist">
                <img
                  src="/media/_versions/homepage/1709340515ddd5c5cabe57f37e593ae9_medium.jpg"
                  alt="Lens huren"
                /><img
                  src="/media/_versions/homepage/abb4a5ffc447e1ea3c876afce8a47434_medium.jpg"
                  alt="Fujinon Cine Lenzen huren"
                />
              </div>
            </a>
          </div>
          <div class="card-body mt-n1 py-4 text-center">
            <h2 class="h5 mb-1">Lenzen</h2>
            <a
              class="btn btn-pill btn-outline-primary btn-sm"
              href="/verhuur/lenzen/"
              >Lens huren</a
            >
          </div>
        </div>

        <div class="card border-1 px-3 pt-3">
          <div style="height: 230px">
            <a class="card-img-tiles" href="/verhuur/licht/">
              <div class="main-img">
                <img
                  src="/media/_versions/homepage/c40006109d786252f458537169e014c3_medium.png"
                  alt="Licht huren"
                />
              </div>
              <div class="thumblist">
                <img
                  src="/media/_versions/homepage/e2ab9bf47de375ef0da6ab50af638363_medium.jpg"
                  alt="Audio huren"
                /><img
                  src="/media/_versions/homepage/b0b1361a33286d6f54465912c70ab0a3_medium.jpg"
                  alt="Licht huren"
                />
              </div>
            </a>
          </div>
          <div class="card-body mt-n1 py-4 text-center">
            <h2 class="h5 mb-1">Licht &amp; Geluid</h2>
            <a
              class="btn btn-pill btn-outline-primary btn-sm"
              href="/verhuur/licht/"
              >Licht huren</a
            >
            <a
              class="btn btn-pill btn-outline-primary btn-sm"
              href="/verhuur/audio/"
              >Audio huren</a
            >
          </div>
        </div>
      </div>
    </section>

    <!-- Brands carousel-->
    <section class="container pb-4 mt-md-3">
      <h2 class="h3 text-center pb-4">Verkoop van de volgende merken:</h2>
      <div
        class="owl-carousel"
        data-owl-carousel='{ "nav": false, "dots": true, "loop": true, "margin": 30, "autoplay": true, "autoplayTimeout": 4000, "responsive": {"0":{"items":2, "margin": 15},"460":{"items":3, "margin": 15}, "768":{"items":4}} }'
      >
        <a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/01_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/02_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/03_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/04_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/05_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/06_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/07_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/08_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/09_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/10_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/11_medium.png"
            style="width: 150px"
            alt="Brand" /></a
        ><a class="d-block bg-white border py-3 py-sm-4" href="#"
          ><img
            class="d-block mx-auto"
            src="/media/_versions/homepage/12_medium.png"
            style="width: 150px"
            alt="Brand"
        /></a>
      </div>
    </section>
    <!-- Sale counter-->
    <section
      class="container-fluid mt-4 px-0"
      ng-controller="AgendaController as agendaCtrl"
    >
      <div class="bg-position-center">
        <div class="row pt-lg-4 no-gutters">
          <div class="bg-faded-info col-lg-1"></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pr-5 pl-5 pl-lg-0">
            <div class="px-6 px-lg-0 py-5">
              <h3 class="font-weight-light pb-2">
                Aankomende cursussen en opleidingenmm<span
                  class="badge badge-danger font-size-xs ml-3 align-middle"
                  ><!--DATUM: XX-XX-XXXX--></span
                >
              </h3>
              <div style="">
                <div
                  class="card my-1"
                  v-for="run in this.runs"
                  :key="run.id"
                  style="width: 100%; height: 100%"
                  datetime="run.start_date.format('YYYY-MM-DD')"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="card-body p-3">
                        <h5 class="card-title" style="height: 1em">
                          <a
                            style="color: #222222"
                            :href="`/opleidingen/${run.course.slug}`"
                          >
                            {{ run.course.name }}
                          </a>
                        </h5>
                        <p class="card-text font-size-sm">
                          <span class="font-italic"
                            >Start:
                            {{
                              moment(run.start_date).format("DD MMMM YYYY")
                            }}&nbsp;</span
                          >
                          <span
                            v-if="run.course.seo_description"
                            class="text-muted course-description"
                            v-html="run.course.seo_description"
                          ></span>
                          <span
                            v-else
                            class="text-muted course-description"
                            v-html="run.course.description"
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a class="btn btn-primary mt-4" href="/agenda/"
                >Meer cursussen &amp; opleidingen<i
                  class="ml-2"
                  data-feather="arrow-right"
                ></i
              ></a>
            </div>
          </div>
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/homepage/productie.jpg);
              background-size: cover;
              background-position: right;
            "
          ></div>
        </div>
      </div>
    </section>
    <section class="container-fluid px-0">
      <div class="bg-position-center" id="inloopdagen-widget-contianer">
        <div class="row no-gutters">
          <div
            class="col-lg-6"
            style="
              background-image: url(/media/uploads/homepage/promo-banner.jpg);
              background-size: cover;
              background-position: center;
            "
          ></div>
          <div class="bg-faded-info col-lg-5 col-md-12 pl-5 pr-5 pr-lg-0">
            <div class="px-6 px-lg-0 py-5">
              <EditableBlock
                v-if="page_store.blocks"
                :blocks="page_store.blocks"
                :page_id="page_store.page.id"
                block_name="block-inloopdagen"
                type="blocks"
              />
              <OpenDoorDayWidget />
              <a class="btn btn-primary mt-3" href="/inloopdagen/"
                >Meer informatie<i class="ml-2" data-feather="arrow-right"></i
              ></a>
            </div>
          </div>
          <div class="bg-faded-info col-lg-1"></div>
        </div>
      </div>
    </section>
    <!-- Blog + Insta split section-->
    <section class="container-fluid px-0 border-bottom">
      <div class="row no-gutters">
        <div class="col-md-6 py-5 px-4" style="background-color: #efefef">
          <div class="mx-auto py-3" style="max-width: 25rem">
            <h2 class="mb-1">Lees ons blog</h2>
            <p class="opacity-75 pb-3">
              Oproepen een aankodigingen in de filmwereld
            </p>
            <a class="btn btn-outline-dark" href="https://blog.openstudio.nl"
              ><i class="mr-2" data-feather="book"></i>Go to blog</a
            >
          </div>
        </div>
        <div class="col-md-6 bg-secondary py-5 px-4">
          <div class="mx-auto py-3" style="max-width: 25rem">
            <h2 class="mb-1">Follow on Instagram</h2>
            <p class="opacity-75 pb-3">#openstudioamsterdam</p>
            <a
              class="btn btn-dark"
              href="https://www.instagram.com/openstudioamsterdam/"
              ><i class="mr-2" data-feather="instagram"></i>Follow us</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { pageStore } from "@/stores/page";
import axios from "axios";
import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue";
import EditableBlock from "@/components/EditableBlock.vue";

export default {
  name: "HomeView",
  components: {
    EditableBlock,
    OpenDoorDayWidget,
  },
  data: function () {
    return {
      runs: [],
      config: {},
    };
  },
  methods: {
    fetchData() {
      let params = {
        params: {
          has_course: true,
          page_size: 4,
          ordering: "start_date",
        },
      };
      axios.get("/api/runs/", params).then((response) => {
        this.runs = response.data.results;
      });
      axios.get("/api/homepageconfig/").then((response) => {
        this.config = response.data;
        setTimeout(() => {
          this.$nextTick(function () {
            var owl = window.$(".main-carousels");
            owl.trigger("destroy.owl.carousel");
            window
              .$(".main-carousels")
              .owlCarousel({
                items: 1,
                nav: true,
                dots: false,
                loop: true,
                autoHeight: true,
              });
              var triggerCarousel = '.trigger-carousel';
              var targetCarousel = window.$(triggerCarousel).data('target-carousel');
              window.$(".trigger-carousel").on('change.owl.carousel', function (event) {
                if (event.namespace && event.property.name === 'position') {
                  var target = event.relatedTarget.relative(event.property.value, true);
                  window.$(targetCarousel).owlCarousel('to', target, 350, true);
                }
              });
          });
        }, 50);
      });
    },
  },
  created() {
    this.fetchData();
  },
  setup() {
    const page_store = pageStore();
    return {
      page_store,
    };
  },
};
</script>

<style>
.course-description > p:first-child {
  display: inline;
}
.main-carousels .owl-item h2{
  margin-bottom: 0.25rem !important;
}
.main-carousels .owl-item h3{
  opacity: .70;
  font-weight: 300;
  margin-bottom: 0.75rem;
  padding-bottom: 1rem;
}
</style>
