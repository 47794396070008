<template>
  <div id="main">
    <div class="toast-container toast-top-right">
      <div
        id="add-to-cart-toast"
        data-delay="3000"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header bg-primary text-white">
          <i
            class="mr-2"
            data-feather="check-circle"
            style="width: 1.25rem; height: 1.25rem"
          ></i>
          <span class="font-weight-semibold mr-auto">Artikel Toegevoegd</span>
          <button
            type="button"
            class="close text-white ml-2 mb-1"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-primary">
          Het Artikel zit in je winkelwagentje.
        </div>
      </div>
    </div>
    <div class="toast-container toast-top-right">
      <div
        id="empty-cart-toast"
        data-delay="3000"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header bg-primary text-white">
          <i
            class="mr-2"
            data-feather="check-circle"
            style="width: 1.25rem; height: 1.25rem"
          ></i>
          <span class="font-weight-semibold mr-auto">Leeg mandje</span>
          <button
            type="button"
            class="close text-white ml-2 mb-1"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-primary">
          Winkelwagentje is leeg.
        </div>
      </div>
    </div>
    <div class="toast-container toast-top-right">
      <div
        id="unknown-error-toast"
        data-delay="3000"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header bg-danger text-white">
          <i
            class="mr-2"
            data-feather="slash"
            style="width: 1.25rem; height: 1.25rem"
          ></i>
          <span class="font-weight-semibold mr-auto">Fout</span>
          <button
            type="button"
            class="close text-white ml-2 mb-1"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-danger">
          Er gaat iets fout.
        </div>
      </div>
    </div>
    <div class="toast-container toast-top-right">
      <div
        id="success-toast"
        data-delay="3000"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header bg-success text-white">
          <i
            class="mr-2"
            data-feather="check"
            style="width: 1.25rem; height: 1.25rem"
          ></i>
          <span class="font-weight-semibold mr-auto">Gelukt</span>
          <button
            type="button"
            class="close text-white ml-2 mb-1"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-success">
          Actie gelukt.
        </div>
      </div>
    </div>
    <CartOffcanvas />
    <SearchOffcanvas />
    <AccountOffcanvas />
    <NavBar />
    <router-view />
    <OSFooter />
  </div>
</template>

<script>
import feather from "feather-icons";
import NavBar from "@/components/NavBar.vue";
import OSFooter from "@/components/OSFooter.vue";
import SearchOffcanvas from "@/components/SearchOffcanvas.vue";
import AccountOffcanvas from "@/components/AccountOffcanvas.vue";
import CartOffcanvas from "@/components/CartOffcanvas.vue";
import { userStore } from "@/stores/user";
import { pageStore } from "@/stores/page";

export default {
  name: "app",

  components: {
    NavBar,
    OSFooter,
    SearchOffcanvas,
    AccountOffcanvas,
    CartOffcanvas,
  },
  created() {
    this.user_store.check_login();
    this.page_store.get_page(this.$route.path);
    this.page_store.get_widgets();
    setTimeout(() => {
      this.$nextTick(function () {
        feather.replace();
      });
    }, 500);
    setTimeout(() => {
      this.$nextTick(function () {
        feather.replace();
      });
    }, 2000);
  },
  setup() {
    const user_store = userStore();
    const page_store = pageStore();
    return {
      user_store,
      page_store,
    };
  },
  updated: function () {
    setTimeout(() => {
      this.$nextTick(function () {
        feather.replace();
      });
    }, 500);
  },
};
</script>
<style>
#main {
  position: relative;
}
</style>
