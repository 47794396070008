<template>
  <form novalidate @submit.prevent>
    <div class="row">
      <div :class="colsize">
        <div class="form-group">
          <label for="reg-fn">Voornaam</label>
          <input
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.first_name }"
            type="text"
            v-model="first_name"
            name="register-first_name"
            required
            id="reg-fn"
          />
          <div v-if="errors.first_name" class="invalid-feedback">
            {{ errors.first_name[0] }}
          </div>
        </div>
      </div>
      <div :class="colsize">
        <div class="form-group">
          <label for="reg-ln">Achternaam</label>
          <input
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.last_name }"
            v-model="last_name"
            type="text"
            name="register-last_name"
            required
            id="reg-ln"
          />
          <div v-if="errors.last_name" class="invalid-feedback">
            errors.last_name[0]
          </div>
        </div>
      </div>
      <div :class="colsize">
        <div class="form-group">
          <label for="reg-email">E-mail Address</label>
          <input
            class="form-control"
            type="email"
            v-bind:class="{ 'is-invalid': errors.email }"
            v-model="email"
            name="register-email"
            required
            id="reg-email"
          />
          <div v-if="errors.email" class="invalid-feedback">
            {{ errors.email[0] }}
          </div>
        </div>
      </div>
      <div :class="colsize">
        <div class="form-group">
          <label for="reg-password">Password</label>
          <input
            class="form-control"
            type="password"
            v-bind:class="{ 'is-invalid': errors.password }"
            v-model="password"
            name="register-password"
            required
            id="reg-password"
          />
          <div v-if="errors.password" class="invalid-feedback">
            {{ errors.password[0] }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button
        v-on:click="doRegister"
        class="btn btn-primary"
        type="submit"
        id="register-submit"
        v-bind:class="{ disabled: !enabled }"
      >
        Sign Up
      </button>
    </div>
    <br />
    <div class="form-group col-sm-12">
      <div class="form-group" v-if="errors.message">
        {{ errors.message }}
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { userStore } from "@/stores/user";

export default {
  name: "RegistrationWidget",
  props: [
    "colsize",
    "redirect",
  ],
  data: function () {
    return {
      enabled: true,
      errors: {},
      email: "",
      password: "",
      first_name: "",
      last_name: "",
    };
  },
  methods: {
    doRegister: function () {
      this.errors = {};
      var payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        username: this.email,
        password: this.password,
        password2: this.password,
        redirect: this.redirect,
      };
      axios.post("/api/auth/register/", payload).then(
        () => {
          window.location.href = `/activate-account`;
        },
        (error) => {
          if (error.response) {
            this.errors = error.response.data;
          }
        }
      );
    },
  },
  computed: {
    user() {
      return this.store.user;
    },
  },
  watch: {
    user: function () {
      if (this.store.user) {
        this.enabled = false;
        this.errors.message = "Je hebt al een account";
      }
    },
  },
  setup() {
    const store = userStore();
    return {
      store,
    };
  },
};
</script>

<style scoped></style>
