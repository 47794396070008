import Vue from "vue";
import { BootstrapVue } from 'bootstrap-vue'

import VueCookies from "vue-cookies";

import { createPinia, PiniaVuePlugin } from "pinia";

import ContentTools from "ContentTools";

import axios from "axios";

import "font-awesome/css/font-awesome.css";
import "ContentTools/build/content-tools.min.css";
import moment from "moment";
import round from "vue-round-filter";

import App from "./App.vue";
import router from "./router";

// axios.defaults.withCredentials = true

moment.locale("nl");

Vue.config.ignoredElements = ["data-owl-carousel"];
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

var vue = new Vue({
  pinia,
  router,
  filters: {
    round,
  },
  render: (h) => h(App),
}).$mount("#app");

import $ from "jquery";

// eslint-disable-next-line
if (window.load_ct === "true" || process.env.VUE_APP_EDITOR == "yes") {
  window.ct = ContentTools;
  ContentTools.DEFAULT_TOOLS = [
    ["bold", "italic", "link", "align-left", "align-center", "align-right"],
    [
      "heading",
      "subheading",
      "paragraph",
      "unordered-list",
      "ordered-list",
      "table",
      "indent",
      "unindent",
      "line-break",
    ],
    ["video", "preformatted"],
    ["undo", "redo", "remove"],
  ];

  Vue.prototype.ctstate = { editing: false };

  window.addEventListener("load", function () {
    var editor, r;

    setTimeout(() => {
      editor = ContentTools.EditorApp.get();
      editor.init("*[data-editable]", "data-name");
      window.editor = editor;

      editor.addEventListener("start", function () {
        Vue.prototype.ctstate.editing = true;
      });
      editor.addEventListener("stop", function () {
        Vue.prototype.ctstate.editing = false;
      });
      editor.addEventListener("saved", function (ev) {
        var regions;

        // Check that something changed
        regions = ev.detail().regions;
        if (Object.keys(regions).length == 0) {
          return;
        }

        // Set the editor as busy while we save our changes
        this.busy(true);

        var headers = {
          "X-CSRFToken": vue.$cookies.get("csrftoken"),
        };

        for (r in regions) {
          let blockid = $("*[data-name=" + r + "]")[0].dataset.block;
          let type = $("*[data-name=" + r + "]")[0].dataset.type;
          let page_id = $("*[data-name=" + r + "]")[0].dataset.pageid;
          if (regions[r] == "<p>\n    \n</p>") {
            regions[r] = "";
          }
          let payload = {
            name: r,
            content: regions[r],
            page_id: page_id,
          };
          if (blockid) {
            Promise.resolve(
              axios
                .put("/api/main/" + type + "/" + blockid + "/", payload, {
                  headers,
                })
                .then(
                  () => {
                    new ContentTools.FlashUI("ok");
                  },
                  (error) => {
                    if (error.response) {
                      new ContentTools.FlashUI("no");
                    }
                  }
                )
            ).finally(() => {
              this.busy(false);
            });
          } else {
            Promise.resolve(
              axios.post("/api/main/" + type + "/", payload, { headers }).then(
                () => {
                  new ContentTools.FlashUI("ok");
                },
                (error) => {
                  if (error.response) {
                    new ContentTools.FlashUI("no");
                  }
                }
              )
            ).finally(() => {
              this.busy(false);
            });
          }
        }
      });
    }, 1000);
  });
}
