<template>
    <div class="drw-container">
      <div
        v-if="!editing"
        class="row range-picker py-4 my-4"
      >
        <div class="col-12 text-center">
          <a style="color: #222" href="#" @click.prevent="editing = true">
            <span class="d-none d-md-inline">Huurperiode: </span>
            Ophalen
            <b>{{ moment(cart.cart.start_date).format("D MMMM YYYY") }}</b>
            tussen <b>{{ cart.pickup_slot_description }}</b
            >. <br class="d-inline d-md-none" />Terug
            <b>{{ moment(cart.cart.end_date).format("D MMMM YYYY") }}</b> tussen
            <b>{{ cart.return_slot_description }}</b
            >.
          </a>
          &nbsp;
          <span @click="resetPeriod" class="item-al-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-rotate-ccw"
              data-v-5551ef93=""
            >
              <polyline points="1 4 1 10 7 10"></polyline>
              <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
            </svg>
          </span>
        </div>
      </div>
      <div
        v-if="editing"
        class="row range-picker py-2 my-4 justify-content-md-center"
        id="range-picker-container"
      >
        <div class="col-12 col-md-3 col-lg-4 col-xl-6">
          <div class="form-group">
            <label for="f1">Huurperiode</label>
            <LitePickerInputField class="d-none d-sm-block" id="lp1" cols="2" />
            <LitePickerInputField class="d-block d-sm-none" id="lp2" cols="1" />
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2">
          <div class="form-group">
            <label for="f1">Tijd ophalen</label>
            <select
              v-model="cart.cart.pickup_slot"
              @change="changeTimeSlot"
              class="form-control custom-select"
              required
            >
              <option
                v-for="slot in timeslots"
                :key="slot.slot"
                :value="slot.slot"
              >
                {{ slot.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2">
          <div class="form-group">
            <label for="f1">Tijd terug</label>
            <select
              v-model="cart.cart.return_slot"
              @change="changeTimeSlot"
              class="form-control custom-select"
              required
            >
              <option
                v-for="slot in timeslots"
                :key="slot.slot"
                :value="slot.slot"
              >
                {{ slot.description }}
              </option>
            </select>
          </div>
        </div>
        <!--<div class="col">
          <div class="form-group">
            <label class="d-none d-md-inline-block" for="f1">&nbsp;</label>
            <button  @click="editing = true" type="button" class="mt-3 mt-md-0 btn btn-secondary btn-block">Sluiten</button>
          </div>
        </div>-->
      </div>
    </div>
</template>

<script>
import { cartStore } from "@/stores/cart";
import LitePickerInputField from "@/components/LitePickerInputField.vue";

export default {
  name: "DateRangeWidget",
  components: {
    LitePickerInputField,
  },
  methods: {
    changeTimeSlot() {
      // console.log(this.cart.cart.pickup_slot, this.cart.cart.return_slot)
      this.cart.setPeriod();
    },
    resetPeriod() {
      //this.cart.resetPeriod();
    },
  },
  data: function () {
    return {
      editing: true,
      timeslots: [
        { description: "09:00 - 10:00", slot: 0 },
        { description: "10:00 - 13:00", slot: 1 },
        { description: "13:00 - 16:00", slot: 2 },
        { description: "16:00 - 17:00", slot: 3 },
      ],
    };
  },
  computed: {},
  watch: {},
  setup() {
    const cart = cartStore();
    return {
      cart,
    };
  },
};
</script>

<style scoped>
.range-picker {
  /*background-color: #a2c0d5; */
  border: 1px solid #eee;
}
.time-slot {
  width: 130px;
}
#range-picker-container {
  position: relative;
}
.item-al-btn {
  background-color: gray;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.item-al-btn > .feather {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-top: 4px;
  margin-left: 0px;
  vertical-align: top;
}
.item-remove-btn {
  background-color: gray;
}
.drw-container{
  margin-left: 15px;
  margin-right: 15px;
}
</style>
